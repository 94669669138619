import React, { useState } from "react";
import classes from "./Topbar.module.css";
import { ReactComponent as LogoIcon } from "./img/logo.svg";
import { ReactComponent as NameIcon } from "./img/name.svg";
import { ReactComponent as MenuIcon } from "./img/menu.svg";
import cx from "classnames";
import Links from "./Links";
import Links1 from "./Links1";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "assets/close.svg";

export default function Topbar(props) {
  const { onNavChange, isShowNav } = props;

  const nav = useNavigate();

  const home = () => {
    window.location = "/";
  };

  return (
    <div className={classes.topbar}>
      <div className={classes.logo} onClick={home}>
        <LogoIcon className={classes.logo1} />
        <NameIcon className={classes.logo2} />
      </div>
      <div className={classes.links}>
        <Links1 />
      </div>
      {isShowNav ? (
        <CloseIcon className={classes.menu} onClick={() => onNavChange()} />
      ) : (
        <MenuIcon className={classes.menu} onClick={() => onNavChange()} />
      )}
    </div>
  );
}
