import React, { useEffect } from "react";
import cx from "classnames";
import classes from "./Editor.module.css";

import "./style.css";

export default function Editor(props) {
  const { data, className } = props;

  return (
    <div
      className={cx(classes.editor, className)}
      dangerouslySetInnerHTML={{ __html: data }}
    ></div>
  );
}
