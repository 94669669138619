import React, { useState } from "react";
import classes from "./AboutMe.module.css";
import ImageUrl from "assets/image1.png";
import BtnUrl from "assets/btn1.png";
import { ReactComponent as RectIcon } from "./img/rect1.svg";
import { useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


export default function AboutMe() {
  const nav = useNavigate();
  return (
    
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span style={{ color: "#000000" }}>走进我们。</span>
        忠诚勤奋、敬业报国。
      </div>
      <div className={classes.content}>
        <div className={classes.contentLeft}>
          <img className={classes.img} src={ImageUrl} />
          <RectIcon className={classes.rect1}></RectIcon>
          <div className={classes.rect2}></div>
          <div className={classes.rect3}></div>
        </div>
        <div className={classes.text}>
        北京市电加工研究所有限公司成立于1978年，国有独资，国家级专精特新小巨人企业，主营多轴联动精密数控电火花成形加工、电火花小孔加工机床及激光、超声等系列化国内、国际领先产品。
成立40余年来，坚持以特殊材料特种加工技术、精密机床与精密制造为核心，聚焦航空航天、能源、模具等领域装备与技术需求，开发自主知识产权系列化国内、国际领先产品及成套工艺技术。先后主持参与国家科技重大专项、国家“863”等重大项目近10项，始终处于电加工行业领先地位，在航空航天、军工能源、汽车、精密模具、工业刀具等领域获得广泛应用与认可。
        </div>
        <RectIcon className={classes.rect4}></RectIcon>
      </div>

      <div className={classes.items}>
        <div className={classes.item}>
          <div className={classes.number}>
            <CountUp edraw={true}  end={80} useEasing={true} duration={2.3} suffix="+"  >
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </div>
          <div>国家、国际等奖励</div>
        </div>
        <div className={classes.item}>
          <div className={classes.number}>
            <CountUp start={0}  end={160} suffix="+" useEasing={true}  duration={2} >
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start}>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </div>
          <div>授权专利</div>
        </div>
        <div className={classes.item}>
          <div className={classes.number}>
            <CountUp  start={0} suffix="+" useEasing={true} end={200} duration={2.7} >
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start}>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </div>
          <div>纵横向项目</div>
        </div>
        <div className={classes.right}>
          <img
            className={classes.image}
            src={BtnUrl}
            onClick={() => nav(`/about`, {beforeRemove: ()=> {
              console.info("before remove")
            }})}
          />
        </div>
      </div>
    </div>
  );
}
