import React from "react";
import classes from "./Honor.module.css";


import IMG1 from "./honors/img1.png";
import IMG2 from "./honors/img2.png";
import IMG3 from "./honors/img3.png";
import IMG4 from "./honors/img4.png";
import IMG5 from "./honors/img5.png";
import IMG6 from "./honors/img6.png";
import IMG7 from "./honors/img7.png";
import IMG8 from "./honors/img8.png";
import IMG9 from "./honors/img9.png";
import IMG10 from "./honors/img10.png";
import IMG11 from "./honors/img11.png";
import IMG12 from "./honors/img12.png";
import IMG13 from "./honors/img13.png";
import IMG14 from "./honors/img14.png";
import IMG15 from "./honors/img15.png";
import IMG16 from "./honors/img16.png";

const arr = [
  IMG4,   IMG8, IMG9,  IMG10,
  IMG2, IMG6 , IMG1, IMG11,
  IMG3, IMG12, IMG7, IMG5,  
  IMG13, IMG14, IMG15, IMG16
];



export default function Honor() {
  return (
      <div
        className={classes.wrapper}
      >
        <div className={classes.title}>
          <span style={{ color: "rgba(0, 0, 0, 1)" }}>核心客户。</span>超过 80
          家企业单位的优先选择。
        </div>

        <div className={classes.items}>
          {
            arr.map(image => (<img className={classes.images} src={image}/>))
          }
        </div>
      </div>
     
  );
}


