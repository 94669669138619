
import React, {useEffect, useState} from 'react'
import classes from './Netwrok.module.css'


export default function Netwrok() {  
  const url = "https://img1.biem.com.cn/video/network.v4.mp4"
  const onVideoLoaded = ()=> {
    const cover = document.getElementById("sale-work-cover")
    cover.style.display = "none"
  }

  return <div className={classes.wrapper}>
          <div className={classes.title}><span style={{color: 'white'}}>服务网络。</span>覆盖国内超过110个省市及地区。</div>
          <video
            id="sale-work"
            nocontrols
            autoPlay
            playsInline
            webkit-playsinline="true"
            muted
            x5-video-player-type="h5"
            loop
            onLoadedData={onVideoLoaded}
          >
            <source src={url} type="video/mp4" />
          </video>
          <img id="sale-work-cover" className={classes.cover} src="https://img1.biem.com.cn/video/v1.png"/>
          <div className={classes.mask}></div>

  </div>
}