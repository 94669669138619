import React from "react";
import classes from "./Card.module.css";
import cx from "classnames";
import { ReactComponent as ArrowIcon } from "assets/navArrow.svg";
import { useDevice } from "../../hook/useDevice";

export default function Card(props) {
  const {
    refId,
    title,
    text,
    image,
    onClick,
    index,
    isShowBtn = true,
    id,
  } = props;

  const { isH5 } = useDevice();
  const background = isH5
    ? index % 2 === 0
      ? ""
      : "rgba(44, 96, 245, 0.05)"
    : index % 2 === 0
    ? ""
    : "#F8F9FA";

  const flexDirection = isH5 ? "" : index % 2 === 0 ? "row-reverse" : "row";

  return (
    <div
      id={refId}
      className={classes.card}
      style={{ background, flexDirection }}
    >
      <img src={image} className={classes.image} />
      <div className={classes.left}>
        <div className={classes.name}>{title}</div>
        <div className={classes.text}>{text}</div>
        {isShowBtn && (
          <div className={classes.link} onClick={() => onClick(id)}>
            了解详情
            <ArrowIcon className={classes.icon} />
          </div>
        )}
      </div>
    </div>
  );
}
