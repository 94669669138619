import React, { useMemo } from "react";
import classes from "./Catalog.module.css";
import cx from "classnames";
import { Input } from "antd";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { useCallback } from "react";
import { throttle, debounce } from "lodash";

export default function Catalog(props) {
  const {
    list,
    onInputChange,
    current,
    isShowInput = true,
    placeholder = "",
  } = props;

  const ondebounceInput = useMemo(() => {
    if (!onInputChange) {
      return () => {};
    }
    return debounce(onInputChange, 500);
  }, [onInputChange]);

  return (
    <div className={classes.center} id="catalog-nav">
      <div className={classes.catalog}>
        {list.map((a, i) => (
          <div
            key={i}
            className={cx(classes.item, { [classes.active]: a.id === current })}
            onClick={() => props.onChange(a.id)}
          >
            {a.name}
          </div>
        ))}
      </div>
      <div className={classes.right}>
        {props.children && props.children}

        {isShowInput && (
          <Input
            className={classes.input}
            placeholder={placeholder}
            // iconrender={<SearchOutlined />}
            onChange={(e) => ondebounceInput(e.target.value)}
            suffix={<SearchIcon className={classes.icon} />}
          />
        )}
      </div>
    </div>
  );
}
