import React, { useState } from "react";
import classes from "./Card.module.css";
import { ReactComponent as BtnIcon } from "assets/btn2.svg";
import { useMemo } from "react";
import { useDevice } from "../../hook";
import { Editor } from "../../components";

export default function Card(props) {
  const { d, onClick, btn } = props;

  const [cur, setCur] = useState("key1");

  const { isH5 } = useDevice();

  const BtnComp = btn ?? BtnIcon;

  const arr = useMemo(() => {
    return [
      {
        key: "key1",
        name: "特点",
        content: d.feature,
      },
      {
        key: "key2",
        name: "功能",
        content: d.ability,
      },
      {
        key: "key3",
        name: "应用领域",
        content: d.use,
      },
    ];
  }, [d]);

  const content = useMemo(() => {
    return arr.filter((d) => d.key === cur)[0]?.content;
  }, [arr, cur]);
  return (
    <div className={classes.card}>
      {!isH5 && <div className={classes.title}>{d.name}</div>}

      <div className={classes.card1}>
        <div className={classes.left}>
          <div className={ `${classes.cover} cover` } src={d.cover} style={{"backgroundImage": `url(${d.cover})`}} />
          {/* <img className={classes.image} src={d.cover} /> */}
        </div>

        {isH5 && <div className={classes.title}>{d.name}</div>}

        <div className={classes.right}>
          <div className={classes.items}>
            {arr.map((d, i) => (
              <div
                key={i}
                className={classes.item}
                style={{ fontWeight: d.key === cur ? 700 : 400 }}
                onClick={() => setCur(d.key)}
              >
                {d.name}
              </div>
            ))}
          </div>
          <div className="rightContent">
          <Editor className={classes.text} data={content} />
          </div>

          <BtnComp className={classes.button} onClick={onClick} />
        </div>
      </div>
    </div>
  );
}
