import React, { useEffect, useState } from "react";
import classes from "./Preview.module.css";
import { ReactComponent as ExpendIcoon } from "assets/downRightAndUpLeft.svg";
import { ReactComponent as ForwardIcon } from "assets/forward.svg";
import { useMemo } from "react";
import { useDevice } from "../../hook";
import cx from "classnames";
import Editor from "../../components/Editor";

export default function Preview(props) {
  const { list, current, onClose } = props;

  // const [cur, setCur] = useState(null);
  const [index, setIndex] = useState(0);
  const [imgIndex, setImgIdex] = useState(0);

  const cur = useMemo(() => {
    return list[index] ?? list[0];
  }, [index, list]);

  const curImg = useMemo(() => {
    return cur?.image[imgIndex];
  }, [imgIndex, list, cur]);

  useEffect(() => {
    setIndex(current ?? 0);
  }, []);

  return (
    <div className={classes.wrapper} onClick={onClose}>
      {cur && (
        <div
          className={classes.content}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classes.name}>{cur.title}</div>
          <div className={classes.item}>
            <div className={classes.left}>
              <img className={classes.image} src={curImg} />
              <div className={classes.images}>
                {cur.image.map((d, i) => (
                  <img
                    className={cx(classes.image1, {
                      [classes.active]: i === imgIndex,
                    })}
                    src={d}
                    onClick={() => setImgIdex(i)}
                  />
                ))}
              </div>
            </div>
            <Editor className={classes.text} data={cur.content} />
          </div>
        </div>
      )}

      <div className={classes.bar}>
        <div className={classes.leftBar}>
          <ForwardIcon
            className={classes.icon}
            style={{
              transform: "rotate(180deg)",
              color: index === 0 ? "#D9D9D9" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIndex(Math.max(0, index - 1));
              setImgIdex(0);
            }}
          />
          <ForwardIcon
            className={classes.icon}
            style={{ color: index === list.length - 1 ? "#D9D9D9" : "" }}
            onClick={(e) => {
              e.stopPropagation();
              setIndex(Math.min(list.length - 1, index + 1));
              setImgIdex(0);
            }}
          />
        </div>
        <div className={classes.rightBar}>
          <ExpendIcoon className={classes.icon} onClick={onClose} />
        </div>
      </div>
    </div>
  );
}
