import React from "react";
import classes from "./NewsInformation.module.css";
import { ReactComponent as ButtonIcon } from "assets/btn2.svg";

import NewBgUrl from "./img/newBg1.png";
import { ReactComponent as NewIcon1 } from "./img/newBg1.svg";
import { Swiper } from "@/components";
import dayjs from "dayjs";

export default function NewsInformation(props) {
  const { list } = props;
  const goToNews = (id) => {
    window.location.href = `/news/${id}`;
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span style={{ color: "#FFFFFF" }}>新闻资讯。</span>最新的 BIEM
        信息和行业新闻。
      </div>
      <div className={classes.items}>
        <Swiper list={list ?? []} count={1} color="white" mode="splide">
          {(d, i) => (
            <div className={classes.card}>
              <img className={classes.image1} src={d.cover} />
              <div className={classes.right}>
                <div className={classes.name}>{d.category_name}</div>
                <div className={classes.text}>{d.title}</div>
                <div className={classes.date}>
                  {dayjs.unix(d.published_at).format("YYYY 年 MM 月 DD 日")}
                </div>
                <ButtonIcon
                  className={classes.icon}
                  onClick={() => goToNews(d.id)}
                />
              </div>
            </div>
          )}
        </Swiper>
      </div>

      <NewIcon1 className={classes.new2} />
      <img className={classes.new1} src={NewBgUrl} />
    </div>
  );
}
