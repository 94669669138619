import React, { useEffect } from "react";
import classes from "./Detail.module.css";
import { useApi } from "../../hook";
import { fetchNewsDetail } from "../../api";
import { useParams } from "react-router-dom";
import commonCss from "@/App.module.css";
import dayjs from "dayjs";
import events from "@/event";
import { Editor } from "../../components";

export default function Detail() {
  const { id = "" } = useParams();
  const [data] = useApi(fetchNewsDetail, id);

  useEffect(() => {
    if (!data) return;
    events.emit("catalog", data.title);
    return () => {
      events.emit("catalog", "");
    };
  }, [data]);

  if (!data) return null;
  return (
    <div className={classes.wrapper}>
      <div className={classes.catalog}>{data.category_name}</div>
      <div className={classes.date}>
        {dayjs.unix(data.published_at).format("YYYY 年 MM 月 DD 日")}
      </div>
      <div className={classes.desc}>{data.title}</div>
      <div className={classes.divder}></div>
      <Editor className={classes.content} data={data.content} />
    </div>
  );
}
