import React from "react";
import classes from "./OurProducts.module.css";
import BgIcon from "assets/bg1.png";
import { Swiper } from "@/components";
import { useDevice } from "../../hook/useDevice";
import { useNavigate } from "react-router-dom";

export default function OurProducts(props) {
  const { list } = props;
  const { isH5 } = useDevice();
  return (
    <div
      className={classes.wrapper}
      style={{ backgroundImage: `url(${BgIcon})` }}
    >
      <div className={classes.title}>
        <span style={{ color: "#000000" }}>我们的产品。</span>
        {`无论您的规模，无论您的行业，我们有一个解决方案，可以重塑您的世界。`}
      </div>
      <div className={classes.items}>
        <Swiper list={list} count={3}>
          {(d, i) => <Card d={d} index={i} />}
        </Swiper>
      </div>
    </div>
  );
}

function Card(props) {
  const { d, index } = props;
  const nav = useNavigate();
  return (
    <div
      key={index}
      className={classes.card}
      style={{
        marginRight: (index + 1) % 3 === 0 ? "0px" : "",
      }}
      onClick={() => nav(`/product/detail/${d.id}`)}
    >
      <img className={classes.img1} src={d.cover} />
      <div className={classes.content}>
        <div className={classes.name}>{d.name}</div>
        <div className={classes.type}>{d.model}</div>
        <div className={classes.desc}>{d.description}</div>
      </div>
      <div className={classes.top}>{d.category_name}</div>
    </div>
  );
}
