import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'query-string'
import useQuery from './useQuery.js'

function useQuerySetter() {
  const location = useLocation()
  const navigate = useNavigate()
  const query = useQuery()

  return useCallback(
    (diff, method = 'replace') => {
      const next = qs.stringify({ ...query, ...diff }, { arrayFormat: 'comma' })
      if (next !== location.search) {
        navigate(`${location.pathname}?${next}`, { 
          replace: true,
        })
      }
    },
    [navigate, location, query]
  )
}

export default useQuerySetter
