import React, { useEffect } from "react";
import classes from "./AboutMe.module.css";
import { useLocation } from 'react-router-dom';

import { useApi } from "../../hook";
import { fetchAboutMe } from "../../api";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { useDevice } from "../../hook/useDevice";

export default function AboutMe() {
  const history = useNavigate();

  const [list] = useApi(fetchAboutMe);
  const { isH5 } = useDevice();
  const location = useLocation();
  useEffect(()=> {
    if (location.state){
      const {target} = location.state
      const dom = document.getElementById( `aboutme_${target.name}`);
      dom?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      document.getElementById("main").scrollTop = 0
    }
   
  }, [location?.state?.target?.name, list])

  return (
    <div className={classes.wrapper}>
      {isH5 && <div className={classes.title}>走进我们</div>}

      {(list ?? []).map((d, i) => (
        <Card
          refId={`aboutme_${d.title}`}
          key={i}
          title={d.title}
          text={d.sub_title}
          image={d.cover}
          index={i}
          onClick={() => history(`/about/${d.id}`)}
          id={d.id}
          isShowBtn={d.has_content === 1}
        />
      ))}
    </div>
  );
}
