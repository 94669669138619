import { useMemo } from 'react'
import {  useLocation } from 'react-router-dom'
import qs from 'query-string'

function useQuery() {
  const location = useLocation()

  return useMemo(
    () => qs.parse(location.search, { arrayFormat: 'comma' }),
    [location.search]
  )
}

export default useQuery
