
import React, { useState, useEffect} from 'react'
import { ReactComponent as ConnectIcon } from 'assets/connect.svg'
import { ReactComponent as QrCodeIcon } from 'assets/qrcode.svg'
import { ReactComponent as TopIcon } from 'assets/top.svg'
import { ReactComponent as WeChatIcon } from 'assets/wechat.svg'

import QrcodeUrl from 'assets/qrcode.png'

import classes from './FixedNav.module.css'

export default function FixedNav(props) {
  const [isShowQrCode, setIsShowQrCode] = useState(false);

  const showMessagePanel = () => {
    window._MEIQIA('init')
    window._MEIQIA('showPanel');
  }
  
  useEffect(() => {
    window._MEIQIA('hidePanel');
  })


  const scrollToTop = () => {
    const titleElement = document.getElementById('top')
    titleElement.scrollIntoView({ behavior: 'smooth' })
  };
  const { title, desc} = props;
  return (
    <div className={classes.secondNav}>
      <div className={`${classes.top} ${classes.button}`} onClick={scrollToTop}>
        <TopIcon className={classes.topIcon} />
      </div>
      <div className={`${classes.messagePanel} ${classes.button}`} onClick={showMessagePanel}>
        <ConnectIcon className={classes.connectionIcon} />
        <span>合作咨询</span>
      </div>

      <div 
      className={`${classes.subscribe} ${classes.button}`} 
      onMouseLeave={()=> {setIsShowQrCode(false)}}
      onMouseEnter={()=>{setIsShowQrCode(true)}}>
        <QrCodeIcon className={classes.connectionIcon} />
        <span>关注我们</span>
      </div>
      {isShowQrCode && (
        <div className={classes.subscribeBox}>
        <img className={classes.qrcode1} src={QrcodeUrl} />
        <div className={classes.text}>
          <WeChatIcon/>
          <span>扫码关注我们</span>
        </div>
      </div>
      )}
  </div>
  )
}