import React, { useState, useEffect} from 'react'
import classes from './ModalVideo.module.css'
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";


export default function ModalVideo(props) {  
  const [videoLoading, setVideoLoading] = useState(true);



  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  const { onClose } = props;
  
  return (
    <section className={classes.modal__bg}>
            <div className={classes.modal__align}>
              <div className={classes.modal__content}>
                <IoCloseOutline
                  className={classes.modal__close}
                  arial-label="Close modal"
                  onClick={onClose}
                />
                <div className={classes.modal__video_align}>
                  {videoLoading ? (
                    <div className={classes.modal__spinner}>
                      <BiLoaderAlt
                        className={classes.modal__spinner_style}
                        fadeIn="none"
                      />
                    </div>
                  ) : null}
                  <iframe
                    className={classes.modal__video_style}
                    onLoad={spinner}
                    loading="lazy"
                    width="800"
                    height="500"
                    src={props.src}
                    title=""
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
  )
}