import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDevice } from "../../hook/useDevice";
import { CatalogContext } from "../../context";
import { useLocation } from "react-router-dom";

import Footer1 from "./Footer1";
import Footer2 from "./Footer2";

export default function Footer() {
  const { isH5 } = useDevice();
  const location = useLocation();

  const { catalog } = useContext(CatalogContext);

  // if (isH5) return null;

  const cataList = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "解决方案")?.categories ?? [];
  }, [catalog]);

  const cataList1 = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "产品技术")?.categories ?? [];
  }, [catalog]);
  const cataList2 = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "走进我们")?.categories ?? [];
  }, [catalog]);

  if (location.pathname === "/history") {
    return null;
  }

  return (
    <div>
      <Footer1
        cataList={cataList}
        cataList1={cataList1}
        cataList2={cataList2}
      />
      <Footer2
        cataList={cataList}
        cataList1={cataList1}
        cataList2={cataList2}
      />
    </div>
  );
}
