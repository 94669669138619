import React, { useContext, useMemo } from "react";
import classes from "./index.module.css";
import { Header, Catalog, Card } from "@/components";
import { CatalogContext } from "../../context";
import { useState } from "react";
import { useEffect } from "react";
import { fetchSolutions } from "../../api";
import { usePageApi } from "../../hook";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useQuery from "../../hook/useQuery";
import useQuerySetter from "../../hook/useQuerySetter";
import events from "@/event";
import { Empty } from "antd";

export default function Scheme() {
  const { catalog } = useContext(CatalogContext);
  const { categoryId = "" } = useQuery();
  const setQuery = useQuerySetter();

  const { id = "" } = useParams();
  const history = useNavigate();
  const location = useLocation();

  


  const params = useMemo(() => {
    return {
      category_id: categoryId || id,
    };
  }, [categoryId, id]);

  const [data] = usePageApi(fetchSolutions, params);
  useEffect(()=> {
    if (location.state) {
      document.getElementById("main").scrollTo({
        "top": 500,
        behavior: "smooth"
      })
    } else {
      document.getElementById("main").scrollTop = 10
    }
  }, [ data])

  const cataList = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "解决方案")?.categories ?? [];
  }, [catalog]);

  const firstCatalog = useMemo(() => {
    return cataList.find((d) => d.id === id);
  }, [cataList, id]);

  useEffect(() => {
    if (!firstCatalog) return;
    events.emit("catalog", firstCatalog.name);

    return () => {
      events.emit("catalog", "");
    };
  }, [firstCatalog]);

  const secondCataList = useMemo(() => {
    if (!firstCatalog) return [];
    return firstCatalog.categories ?? [];
  }, [firstCatalog]);

  const onClick = (id) => {
    history(`/solution/detail/${id}`);
  };

  const isEmpty = (data || []).length < 1;

  return (
    <div className={classes.wrapper}>
      <Header
        title={firstCatalog?.name ?? ""}
        desc={firstCatalog?.description ?? ""}
        cover={firstCatalog?.cover ?? ""}
      />
      <Catalog
        isShowInput={false}
        current={categoryId}
        list={[{ name: "所有", id: "" }, ...(secondCataList ?? [])]}
        onChange={(id) => {
          history(`${window.location.pathname}?categoryId=${id}`, {state: {target: "catalog-nav"}});
        }}
      />
      <div className={classes.content}>
        {(data ?? []).map((d, i) => (
          <Card
            key={i}
            id={d.id}
            title={d.title}
            text={d.sub_title}
            image={d.cover}
            index={i}
            isShowBtn={d.has_content == 1}
            onClick={onClick}
          />
        ))}
        {isEmpty && <Empty description={"没有匹配到数据"} />}
      </div>
    </div>
  );
}
