const PRE_URL = 'https://biem.com.cn'

async function request(url) {
  try {
    const data = await fetch(`${PRE_URL}${url}`).then(response => response.json())
    if (data?.code === 200) {
      return data.data;
    }
    return null
  } catch (e) {
    return null
  }
}

// 首页
export async function fetchHome(params) {
  const data = await request(`/api/home?status=${params.status ?? 1}`)
  return data
}
export async function fetchHeadBanner(params) {
  const data = await request(`/api/head_banner?status=${params.status ?? 1}`)
  return data
}
// 类目
export async function fetchCatalogs() {
  const data = await request('/api/categories')
  return data
}

// 走进我们
export async function fetchAboutMe() {
  const data = await request('/api/articles')
  return data
}
export async function fetchAboutMeDetail(id) {
  const data = await request(`/api/article/${id}`)
  return data
}

// 新闻资讯
export async function fetchNews(params) {
  const data = await request(`/api/news?offset=${params.offset || 0}&limit=100&title=${params.title || ''}&category_id=${params.category_id || ''}`)
  return data
}

export async function fetchNewsDetail(id) {
  const data = await request(`/api/new/${id}`)
  return data
}


// 合作交流
export async function fetchCooperations(params) {
  const data = await request(`/api/cooperations?category_id=${params.category_id || ''}`)
  return data
}
export async function fetchCooperationDetail(id) {
  const data = await request(`/api/cooperation/${id}`)
  return data
}


// 解决方案
export async function fetchSolutions(params) {
  const data = await request(`/api/solutions?category_id=${params.category_id || ''}`)
  return data
}
export async function fetchSolutionDetail(id) {
  const data = await request(`/api/solution/${id}`)
  return data
}


// 产品技术
export async function fetchProducts(params) {
  const data = await request(`/api/products?name=${params.name || ''}&category_id=${params.category_id || ''}`)
  return data
}
export async function fetchProductDetail(id) {
  const data = await request(`/api/product/${id}`)
  return data
}

// 招聘

export async function fetchJobs(params) {
  const data = await request(`/api/jobs?offset=${params.offset || 0}&limit=10&title=${params.title || ''}&category_id=${params.category_id || ''}&is_hot=${params.is_hot || false}`)
  return data
}
export async function fetchJobDetail(id) {
  const data = await request(`/api/job/${id}`)
  return data
}

// 获取验证码
export async function fetchClue(id) {
  const data = await request(`/api/clue`)
  return data
}



// 提交客户线索
export async function newContact(param) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(param)
  }

  return fetch(`${PRE_URL}/api/clue`, requestOptions).then(response => response.json())
}