import React, { useContext, useState, useEffect } from "react";
import { ReactComponent as NavArrow } from "assets/navArrow.svg";
import classes from "./News.module.css";
import { Header, Catalog } from "@/components";
import { Empty, Pagination } from "antd";
import { CatalogContext } from "../../context";
import { useMemo } from "react";
import { usePageApi } from "../../hook";
import { fetchNews } from "../../api";
import useQuery from "../../hook/useQuery";
import useQuerySetter from "../../hook/useQuerySetter";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useLocation } from 'react-router-dom';


export default function News() {
  const { offset, title, categoryId = "" } = useQuery();
  const setQuery = useQuerySetter();
  const location = useLocation();
  const history = useNavigate();


  const params = useMemo(() => {
    return {
      title,
      category_id: categoryId,
      limit: 10,
      offset,
    };
  }, [title, offset, categoryId]);

  const { catalog } = useContext(CatalogContext);
  const [data] = usePageApi(fetchNews, params, true);

  useEffect(()=> {
    if (location.state) {
      document.getElementById("main").scrollTo({
        "top": 500,
        behavior: "smooth"
      })
    }
   
  }, [ data])

  const cataList = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "新闻资讯")?.categories ?? [];
  }, [catalog]);

  const onCatalogChange = (id) => {
    history(`${window.location.pathname}?categoryId=${id}`, {state: {target: "catalog-nav"}});
  };
  const onChange = (id) => {
    setQuery({
      offset: id - 1,
    });
  };
  const onInputChange = (value) => {
    setQuery({
      title: value,
    });
  };

  const isEmpty = (data?.data || []).length < 1;

  if (!data) return null;

  return (
    <div className={classes.wrapper}>
      <Header title="新闻资讯" desc="在这里了解最新的 BIEM 消息和行业资讯。" />
      <Catalog
        id="catalog-nav"
        current={categoryId}
        list={[{ name: "所有", id: "" }, ...(cataList ?? [])]}
        placeholder="搜索新闻"
        onChange={onCatalogChange}
        onInputChange={onInputChange}
      />

      <div className={classes.content}>
        {(data?.data || []).map((d) => (
          <Card data={d} />
        ))}
        {isEmpty && <Empty description={"没有匹配到数据"} />}

        {!isEmpty && (
          <Pagination
            current={Number(offset) + 1}
            onChange={onChange}
            total={data?.paging?.total || ""}
          />
        )}
      </div>
    </div>
  );
}

function Card(props) {
  const { data } = props;
  const history = useNavigate();

  return (
    <div className={classes.card}>
      <img className={classes.image} src={data.cover} />
      <div className={classes.left}>
        <div className={classes.text1}>{data.category_name}</div>
        <div className={classes.text2}>{data.title}</div>
        <div className={classes.text3}>
          {dayjs.unix(data.published_at).format("YYYY 年 MM 月 DD 日")}
        </div>
        <div
          className={classes.text4}
          onClick={() => history(`/news/${data.id}`)}
        >
          了解详情
          <NavArrow className={classes.icon} />
        </div>
      </div>
    </div>
  );
}
