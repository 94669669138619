import React, { useMemo } from "react";
import ServicedCompany from "./ServicedCompany";
import AboutMe from "./AboutMe";
import Header from "./Header";
import NewsInformation from "./NewsInformation";
import OurProducts from "./OurProducts";
import Netwrok from "./Netwrok";
import Honor from "./Honor";
import { useApi } from "../../hook";
import { fetchHome } from "../../api";
import useQuery from "../../hook/useQuery";

export default function Home() {
  const { preview } = useQuery();

  const param = useMemo(() => {
    return preview === "true" ? { status: 2 } : {};
  }, [preview]);

  const [data] = useApi(fetchHome, param);
  if (!data) return null;

  return (
    <div>
      {data.banner && data.banner.length > 0 && <Header list={data.banner} />}
      {data.product && data.product.length > 0 && (
        <OurProducts list={data.product} />
      )}

      <AboutMe />
      <Honor />
      {/* <ServicedCompany /> */}
      <Netwrok />
      {data.news && data.news.length > 0 && (
        <NewsInformation list={data.news} />
      )}
    </div>
  );
}
