import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Modal } from "antd";
import { fetchClue } from "../../api";
import { useApi, useDevice } from "../../hook";
import classes from "./ContractForm.module.css";
import { newContact } from "@/api";

const { Option, OptGroup } = Select;

const jobs = {
  工程技术: ["总工程师", "工程师"],
  市场销售: ["销售总监", "市场总监"],
  其他: ["其他"],
};

export default function ContractForm(props) {
  const [canSubmit, setCanSubmit] = useState(false);
  const [form] = Form.useForm();
  const { isH5 } = useDevice();

  const [digital, setDigital] = useState();

  useEffect(() => {
    refreshDigital();
  }, []);

  const refreshDigital = () => {
    fetchClue().then((data) => {
      setDigital(data);
    });
  };

  const validateMessages = {
    required: "${label} 是必须的!",
    types: {
      email: "${label} 不是有效的邮箱!",
      number: "${label} 不是有效的数字!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = (values) => {
    const payload = {
      verify_id: digital.verify_id,
      page_url: window.location.href,
      ...values,
    };
    newContact(payload).then((data) => {
      if (!data.success) {
        Modal.error({
          content: data.message,
        });
        refreshDigital();
      } else {
        Modal.success({
          content: "提交成功，我们会在24小时内与您取得联系。",
        });
        form.resetFields();
        refreshDigital();
        setCanSubmit(false);
      }
    });
  };

  const isPhone = (rules, value, callback) => {
    // 手机
    var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (myreg.test(value)) {
      callback();
      return;
    }
    // 座机
    var myreg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
    if (myreg.test(value)) {
      callback();
      return;
    }

    return callback(new Error("请输入正确的电话或手机号码"));
  };
  const onValuesChange = (props, changedValues, allValues) => {
    const canSubmit =
      changedValues.name &&
      changedValues.position &&
      changedValues.telephone &&
      changedValues.verify_code &&
      changedValues.verify_code.length == 4;
    setCanSubmit(canSubmit);
  };

  const tailLayout = {
    wrapperCol: { offset: isH5 ? 0 : 5, span: 19 },
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };

  if (!digital) return null;
  return (
    <div>
      <Form
        form={form}
        class={classes.form}
        onValuesChange={onValuesChange}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item name={["name"]} label="姓名" rules={[{ required: true }]}>
          <Input placeholder="您的姓名" className={classes.input} />
        </Form.Item>
        <Form.Item name={["company"]} label="公司名称">
          <Input placeholder="您的公司" className={classes.input} />
        </Form.Item>

        <Form.Item
          name={["position"]}
          label="职位"
          rules={[{ required: true }]}
        >
          <Select placeholder="您的职位" className={classes.input}>
            {Object.keys(jobs).map((job, i) => (
              <OptGroup label={job}>
                {jobs[job].map((j, k) => (
                  <Option value={j}>{j}</Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["telephone"]}
          label="联系电话"
          rules={[{ required: true }, { validator: isPhone }]}
        >
          <Input placeholder="您的电话" className={classes.input} />
        </Form.Item>
        <Form.Item name={["wechat"]} label="微信">
          <Input placeholder="您的微信号" className={classes.input} />
        </Form.Item>
        <Form.Item name={["remark"]} label="备注">
          <Input.TextArea
            style={{ height: "200px" }}
            placeholder="你可以在这里请求报价或咨询问题，或你对多个设备感兴趣，也可以留下多个设备型号"
          />
        </Form.Item>

        <Form.Item
          name={["verify_code"]}
          label="验证码"
          rules={[{ required: true }]}
        >
          <div class={classes.code}>
            <Input placeholder="请输入右图中的字符" className={classes.input} />
            <img
              class={classes.image}
              onClick={refreshDigital}
              src={digital.verify_code}
            />
          </div>
        </Form.Item>

        <Form.Item name="" label="" {...tailLayout}>
          <Button
            {...tailLayout}
            block
            className={classes.submitButton}
            disabled={!canSubmit}
            type="primary"
            size="large"
            htmlType="submit"
          >
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
