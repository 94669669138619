import React from 'react'
import { Routes, Route, Redirect } from 'react-router-dom';
import Home from './Home'; // 首页
import AboutMe from './AboutMe'; // 走进我们
import AboutMeDetail from './AboutMe/AboutMeDetail'; // 走进我们
import News from './News'; //  新闻咨询
import NewsDetail from './News/Detail'; //  新闻咨询
import Solution from './Solution'; // 解决方案
import SolutionIndex from './Solution/Solution'; // 解决方案
import SolutionDetail from './Solution/Detail'; // 解决方案
import Cooperation from './Cooperation'; // 合作交流
import Product from './Product'; // 产品技术
import ProductDetail from './Product/Detail'; // 产品技术
import Recruit from './Recruit'; // 人才招聘
import RecruitDetail from './Recruit/Detail'; // 人才招聘
import Honor from './Honor'; // 党建文化
import Contact from './Contact'; // 联系我们
import Privacy from './Privacy' // 隐私政策
import History from './History' 


function Route1() {
  return (
    <Routes>
      {/* <Redirect path="/" to="/home"/> */}
      <Route path="/" element={<Home />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/:id" element={<NewsDetail />} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/about/:id" element={<AboutMeDetail />} />
      <Route path="/solution" element={<SolutionIndex />} />
      <Route path="/solution/:id" element={<Solution />} />
      <Route path="/solution/detail/:id" element={<SolutionDetail />} />
      <Route path="/cooperation" element={<Cooperation />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/product/detail/:id" element={<ProductDetail />} />
      <Route path="/jobs" element={<Recruit />} />
      <Route path="/jobs/:id" element={<RecruitDetail />} />
      <Route path="/honor" element={<Honor />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/history" element={<History />} />
    </Routes>
  )
}

export default Route1
