import React, { useContext, useMemo, useEffect } from "react";
import classes from "./Cooperation.module.css";
import { Header, Catalog, Card } from "@/components";
import { fetchCooperations } from "../../api";
import { useApi, useDevice } from "../../hook";
import { useNavigate } from "react-router-dom";
import { CatalogContext } from "../../context";
import useQuery from "../../hook/useQuery";
import useQuerySetter from "../../hook/useQuerySetter";
import { Empty } from "antd";
import { useLocation } from 'react-router-dom';


export default function Cooperation() {
  const { categoryId = "" } = useQuery();
  const nav = useNavigate();
  const setQuery = useQuerySetter();
  const location = useLocation();


  const params = useMemo(() => {
    return {
      category_id: categoryId,
    };
  }, [categoryId]);

  const [list] = useApi(fetchCooperations, params);
  const { catalog } = useContext(CatalogContext);
  const { isH5 } = useDevice();

  useEffect(()=> {
    if (location.state) {
      document.getElementById("main").scrollTo({
        "top": 500,
        behavior: "smooth"
      })
    } else {
      document.getElementById("main").scrollTop = 10
    }
   
  }, [ list])

  const cataList = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "合作交流")?.categories ?? [];
  }, [catalog]);

  const onCatalogChange = (id) => {
    nav(`${window.location.pathname}?categoryId=${id}`, {state: {target: "catalog-nav"}});
  };

  if (!list) return null;

  const isEmpty = (list || []).length < 1;

  return (
    <div className={classes.wrapper}>
      <Header
        title="合作交流"
        desc="我们的合作伙伴遍及全球，精心设计的解决方案帮合作伙伴实现想法，将他们的构想转换成产品。"
      />
      <Catalog
        current={categoryId}
        list={[{ name: "所有", id: "" }, ...(cataList ?? [])]}
        isShowInput={false}
        onChange={onCatalogChange}
      ></Catalog>

      <div className={classes.content}>
        {(list ?? []).map((d, i) => (
          <Card
            key={i}
            title={d.title}
            text={d.sub_title}
            image={d.cover}
            index={i}
            isShowBtn={false}
          />
        ))}
        {isEmpty && <Empty description={"没有匹配到数据"} />}
      </div>
    </div>
  );
}
