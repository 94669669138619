import React, { useContext, useEffect, useMemo, useState } from "react";
import classes from "./Footer2.module.css";
import { ReactComponent as PhoneIcon } from "assets/phone.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { useNavigate } from "react-router-dom";
import events from "@/event";

function Footer2(props) {
  const { cataList, cataList1, cataList2 } = props;
  const nav = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [isShow1, setIsShow1] = useState(false);
  const [isShow2, setIsShow2] = useState(false);

  return (
    <div className={classes.footer1}>
      <div className={classes.content1}>
        <div className={classes.phoneNumber1}>联系电话</div>
        <div className={classes.button1}>
          <PhoneIcon className={classes.phone1} />
          <a href="tel: 010-62017490" style={{ color: "#888888" }}>
            {" "}
            010-62017490
          </a>

        </div>
        <div className={classes.button1}>
          <PhoneIcon className={classes.phone1} />
    
          <a href="tel: 18610881234" style={{ color: "#888888" }}>
            {" "}
            18610881234
          </a>
        </div>
        <div className={classes.text3}>
          或者致电
          <a href="/contact" style={{ color: "#2C60F5" }}>
            产品销售
          </a>
        </div>
      </div>
      <div className={classes.items1}>
        {/* <div className={classes.item1}>
          <div
            className={classes.title1}
            onClick={() => {
              setIsShow(!isShow);
            }}
            style={{ color: isShow ? "#2C60F5" : "" }}
          >
            解决方案
            <PlusIcon className={classes.plus} />
          </div>
          {isShow && (
            <div className={classes.subItem}>
              {(cataList ?? []).map((d, index) => (
                <div
                  key={index}
                  className={classes.text10}
                  onClick={() => {
                    nav(`/solution/${d.id}`);
                    events.emit("scroll");
                  }}
                >
                  {d.name}
                </div>
              ))}
            </div>
          )}
        </div> */}
        <div className={classes.item1}>
          <div
            className={classes.title1}
            onClick={() => {
              setIsShow1(!isShow1);
            }}
            style={{ color: isShow1 ? "#2C60F5" : "" }}
          >
            产品技术
            <PlusIcon className={classes.plus} />
          </div>
          {isShow1 && (
            <div className={classes.subItem}>
              {(cataList1 ?? []).map((d, index) => (
                <div
                  key={index}
                  className={classes.text10}
                  onClick={() => {
                    nav(`/product/${d.id}`);
                    events.emit("scroll");
                  }}
                >
                  {d.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={classes.item1}>
          <div
            className={classes.title1}
            onClick={() => {
              setIsShow2(!isShow2);
            }}
            style={{ color: isShow2 ? "#2C60F5" : "" }}
          >
            联系我们
            <PlusIcon className={classes.plus} />
          </div>
          {isShow2 && (
            <div className={classes.subItem}>
              {(cataList2 ?? []).map((d, index) => (
                <div
                  key={index}
                  className={classes.text10}
                  onClick={() => {
                    nav("/about");
                    setTimeout(() => {
                      const dom = document.getElementById(`aboutme_${d.name}`);
                      dom.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }, 500);
                  }}
                >
                  {d.name}
                </div>
              ))}
              {/* <a href="/history" className={classes.text10}>
                发展历程
              </a> */}
            </div>
          )}
        </div>
      </div>
      <div className={classes.links1}>
        <div>
          <div
            className={classes.link}
            onClick={() => {
              nav("/privacy");
            }}
          >
            <div>隐私政策</div>
            <div>｜</div>
            <div>用户协议</div>
          </div>
        </div>
        <div className={classes.copyright1}>
          Copyright ©️ 2022 - 北京市电加工研究所有限公司
          <div>京ICP备2021023252号-1</div>
          <div>京公安网备 11010802040973</div>
        </div>
      </div>
    </div>
  );
}

export default Footer2;
