import React from "react";
import classes from "./History.module.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { ReactComponent as MoreIcon } from "./img/more.svg";
import { useDevice } from "../../hook/useDevice";

import coverUrl from "./img/corver.png";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import Modal from "./Modal";
import { useState } from "react";

export default function History() {
  let events = [
    {
      year: "1978",
      label: "获批创建",
      end: "",
      title: "",
      cover: "",
      content: ["1978年10月17日,北京市电加工研究所成立"],
    },
    {
      year: "1982",
      end: "1982",
      label: "北京市科技成果二等奖",
      title: "",
      cover: "",
      content: [
        "1982年2月 “JDS多功能晶体管脉冲电源”项目荣获北京市科技成果二等奖；“电火花加工电流波观测RTB分流器”项目荣获北京市科技成果三等奖",
       ],
    },

    {
      year: "1983",
      end: "1983",
      label: "北京市科技成果二等奖",
      title: "",
      cover: "",
      content: [
        "1983年 “聚晶金刚石超硬材料工具电火花加工技术项目”荣获北京市科技成果二等奖。",
       ],
    },

    {
      year: "1984",
      end: "1984",
      label: "国家发明二等奖",
      title: "",
      cover: "",
      content: [
        "1984年 “聚晶金刚石拉丝模电火花加工技术”项目荣获北京市科技成果二等奖。 “聚晶金刚石超硬材料工具电火花加工技术”荣获国家发明二等奖。",
       ],
    },

    {
      year: "1985",
      end: "1993",
      label: "日内瓦世界新发明展览会银奖",
      title: "",
      cover: "",
      content: [
        "1985年 “电弧法烧结石英坩埚稳弧装置”项目荣获北京市科技成果二等奖。“聚晶金刚石工具电火花加工技术” 在第十三届日内瓦世界新发明展览会上荣获银奖。",
       ],
    },
    {
      year: "1988",
      end: "1988",
      label: "北京发明展览会上荣获金奖",
      title: "",
      cover: "",
      content: [
        "“微米级精度高速聚晶金刚石拉丝模的研制”项目荣获北京市科技进步二等奖。",
        "“超硬材料工具电火花超声复合抛光技术” 在北京发明展览会上荣获金奖。",
        "“超硬材料工具电火花超声复合抛光技术”项目在北京国际发明展会上荣获银奖。",
       ],
    },

    {
      year: "1990",
      end: "1990",
      label: "北京市科技成果二等奖",
      title: "",
      cover: "",
      content: [
        "1990年 “超硬材料工具电火花超声复合抛光技术”项目荣获北京市科技成果二等奖。",
       ],
    },
    {
      year: "1991",
      end: "1991",
      label: "北京市科技进步三等奖",
      title: "",
      cover: "",
      content: [
        "1991年“计算机控场效应管电火花脉冲电源”项目、“大功率节能电火花加工脉冲电源”项目荣获北京市科技进步三等奖。",
       ],
    },

    {
      year: "1992",
      end: "1992",
      label: "北京市科技成果二等奖",
      title: "",
      cover: "",
      content: [
        "1992年 “超精集成电路引线框架级进模具国产化及制造工艺研究与开发”项目荣获北京市科技成果二等奖。",
       ],
    },

    {
      year: "1993",
      end: "1993",
      label: "机械工业部科技进步一等奖",
      title: "",
      cover: "",
      content: [
        "1993年 “HFDZ-70型航空发动机涡轮组件电火花机床研制”项目荣获北京市科技进步二等奖。",
        "“聚晶金刚石(PCD)及聚晶立方氮化硼(CBN)放电切割与表面光整加工技术和设备”项目荣获机械工业部科技进步一等奖。",  
       ],
    },
    {
      year: "1994",
      label: "对外合作",
      end: "",
      title: "",
      cover: "",
      content: ["1994年日本Sodick公司技术合作"],
    },
    {
      year: "1995",
      label: "北京市科技进步二等奖",
      end: "",
      title: "",
      cover: "",
      content: ["1995年 “特深精密针孔总成研制”项目荣获北京市科技进步二等奖。"],
    },
    {
      year: "1996",
      label: "迪蒙科研大厦落成",
      end: "",
      title: "",
      cover: "",
      content: ["1996年 “330MW汽轮机高压喷嘴组工艺研究”项目荣获北京市科技进步二等奖。",
      "8月迪蒙科研大厦落成出席庆典的有全国人大常委会副委员长倪志福、原北京市市长焦若愚、北京市政协主席白介夫等出席"],
    },
    {
      year: "1997",
      label: "北京市科技进步一等奖",
      end: "",
      title: "",
      cover: "",
      content: ["1997年 “大颗粒人造单晶金刚石特种精密加工技术”项目荣获北京市科技进步一等奖。"],
    },
    {
      year: "1998",
      label: "羊坊中试基地厂房竣工",
      end: "",
      title: "",
      cover: "",
      content: ["1998年 我所大羊坊中试基地厂房竣工投入使用。",
       "“电火花成型三轴数控精密加工系统的研制与开发”项目荣获北京市科技进步二等奖。"],
    },
    {
      year: "2000",
      label: "转制",
      end: "",
      title: "",
      cover: "",
      content: ["2000 电加工所转制为科技企业。",],
    },
    {
      year: "2003",
      label: "科研楼竣工",
      end: "",
      title: "",
      cover: "",
      content: ["2003 我所大羊坊基地4600多平米的科研综合楼于12月中旬竣工并投入使用。",],
    },
    {
      year: "2005",
      label: "中国机械工业科学技术进步二等奖",
      end: "",
      title: "",
      cover: "",
      content: [
        "2005年 “大面积聚晶金刚石（PCD）高效、绿色精密特种加工技术”项目荣获中国机械工业科学技术进步二等奖。",
        "2006年 “大面积聚晶金刚石（PCD）高效、绿色精密特种加工技术”项目荣获北京市科学技术二等奖。",
        "2008年我所的B35精密数控电火花成形机床荣获中国机床工具工业协会行业最高奖—“春燕奖”。",
      ],
    },
    {
      year: "2009",
      label: "牵头承担国家科技重大专项",
      end: "",
      title: "",
      cover: "",
      content: [
        "特种材料复杂型面加工的五轴联动精密数控电火花成形机床",
      ],
    },
    {
      year: "2010",
      label: "《特种加工手册》正式出版发行",
      end: "",
      title: "",
      cover: "",
      content: [
        "在南京国际博览中心召开的“第六届中国数控机床展览会(CCMT 2010)”上，我所N850精密数控五轴五联动电火花成形机床荣获国产数控机床最高奖—春燕奖。 ",
        "参加在无锡召开的国家04重大科技专项—“电控共轨柴油喷射系统制造技术与关键装备的研发及应用”项目启动会，我所参与承担了04重大专项的子课题“四工位电火花加工喷孔专用机床的研制”。",
        "由我所承担编写的《特种加工手册》正式出版发行。"
      ],
    },
    {
      year: "2011",
      label: "机械工业科学技术一等奖",
      end: "",
      title: "",
      cover: "",
      content: [
        "在由国家发改委、工信部、能源局和国防工业局联合主办的“军工行业与能源装备领域国产数控机床应用座谈会”上，表彰了“国产数控机床优秀合作项目”，我所与西安航空动力股份有限公司合作项目“N850精密数控电火花成形机床”喜获殊荣",
        "“五轴联动精密电火花技术及装备”项目荣获“中国机械工业科学技术奖”一等奖。"
      ],
    },
    {
      year: "2014",
      label: "通过重点实验室认定",
      end: "",
      title: "",
      cover: "",
      content: [
        "电火花加工技术北京市重点实验室”通过北京市重点实验室认定",
        "牵头承担国家科技重大专项-航天复杂零部件超精密微细电火花加工装备与技术"
      ],
    },
    {
      year: "2015",
      label: "超高温陶瓷材料获奖",
      end: "",
      title: "",
      cover: "",
      content: [
        "超高温陶瓷材料电火花加工技术研究及应用”项目荣获2015年度北京市科学技术研究院优秀科技成果奖",
        "参与承担国家科技重大专项——国产高档数控机床与技术在飞航导弹发动机制造领域的综合应用验证及工艺研究应用生产线子任务飞航导弹发动机关键零部件工艺研究及生产线建设"
      ],
    },
    {
      year: "2016",
      label: "领域扩展",
      end: "",
      title: "",
      cover: "",
      content: [
        "“特殊材料闭式结构扭曲型腔零件的精密电火花加工技术的研发及应用”项目荣获北京市科技进步三等奖。",
        "我所研制的五轴联动电火花成形机床，成为首批进入国家国防科技工业局发布的第一批《军工领域国产高档数控机床供应目录》的4项产品之一"
      ],
    },
    {
      year: "2019",
      label: "承担国家重大项目",
      end: "",
      title: "",
      cover: "",
      content: [
        "航空发动机叶片电火花柔性自动化线加工单元等",
      ],
    },
    {
      year: "2021",
      label: "改制为有限责任公司",
      end: "",
      title: "",
      cover: "",
      content: [
        "“航天复杂构件五轴高质高效精密加工成套工艺与制造系统及应用”获中国机械工业科学技术一等奖、“航天复杂构件国产五轴高效精密加工成套工艺与制造系统及示范应用”获北京市科学技术奖一等奖",
      ],
    },
  ];

  const [isShow, setIsShow] = useState(false);
  const [index, setIndex] = useState(0);



  const { isH5 } = useDevice();

  const splideOptions = {
    drag: "free",
    focus: "first",
    snap: true,
    perPage: window.innerWidth / (isH5 ? 130 : 250),
    autoScroll: {
      speed: 1,
    },
    pagination: false,
    arrows: false,
  };

  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const options = {
    particles: {
      number: {
        value: 360,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: "#ffffff",
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.7,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0,
          sync: false,
        },
      },
      size: {
        value: 1,
        random: true,
        anim: {
          enable: false,
          speed: 4,
          size_min: 0.3,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 150,
        color: "#ffffff",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: true,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 600,
        },
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "bubble",
        },
        onclick: {
          enable: true,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 250,
          size: 0,
          duration: 2,
          opacity: 0,
          speed: 3,
        },
        repulse: {
          distance: 400,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
    },
    retina_detect: true,
    background: {
      // "color": "#23274114",
      position: "50% 50%",
      repeat: "no-repeat",
      size: "20%",
    },
  };

  const openModal = (val, i) => {
    setIsShow(true);
    setIndex(i);
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.background} ${classes.bg1}`}></div>
      <div className={`${classes.background}`}>
        <Particles
          id="tsparticles"
          options={options}
          init={particlesInit}
          loaded={particlesLoaded}
        />
      </div>
      <div className={`${classes.text}`}>
        <span>发展历程</span>
      </div>
      <div className={`${classes.background} ${classes.bg2}`}></div>
      <div className={`${classes.background} ${classes.bg3}`}></div>
      {!isShow && (
        <div className={`${classes.background} ${classes.splide}`}>
          <div className={classes.splideContent}>
            <Splide
              aria-label="My Favorite Images"
              extensions={{ AutoScroll }}
              options={splideOptions}
            >
              {events.map((e, i) => (
                <SplideSlide>
                  <div className={classes.year} onClick={() => openModal(e, i)}>
                    <div
                      className={classes.event}
                      style={{ backgroundImage: `url('${e.cover}')` }}
                    >
                      <div>{e.year}</div>
                      <div className={classes.mask}></div>
                    </div>
                    <div className={classes.label}>{e.label}</div>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      )}

      {!isShow && (
        <div className={`${classes.background} ${classes.more}`}>
          <div className={classes.moreIcon}>
            <MoreIcon />
            <span>左右滚动查看更多</span>
          </div>
        </div>
      )}

      <Modal
        isShow={isShow}
        data={events[index]}
        index={index}
        left={events[index - 1]}
        right={events[index + 1]}
        onPre={() => {
          setIndex(Math.max(0, index - 1));
        }}
        onNext={() => {
          setIndex(Math.min(events.length - 1, index + 1));
        }}
        onHidden={() => setIsShow(false)}
      />
    </div>
  );
}
