import { useCallback, useEffect, useMemo, useState } from "react";

export * from './useDevice'



export function useApi(fn, params, isLoad = true) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!isLoad) return
    const load = async () => {
      const res = await fn(params);

      if (res) {
        setData(res);
      }
    };
    load();
  }, [isLoad, params, fn]);
  return [data]
}

export function usePageApi(fn, params = {}, isLoad = true) {
  const [data, setData] = useState();

  const load = useCallback(async () => {
    const res = await fn({ ...params });
    if (res) {
      setData(res);
    }
  }, [params])

  useEffect(() => {
    if (isLoad) {
      load();
    }

  }, [load, isLoad]);


  return useMemo(() => {
    return [data, load]
  }, [data])
}