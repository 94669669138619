import React, { useContext, useEffect, useMemo } from "react";
import classes from "./Solution.module.css";
import { useLocation } from 'react-router-dom';
import { useApi } from "../../hook";
import { fetchAboutMe } from "../../api";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { useDevice } from "../../hook/useDevice";
import { CatalogContext } from "../../context";

export default function AboutMe() {
  const history = useNavigate();

  const { catalog } = useContext(CatalogContext);

  const categories = useMemo(() => {
    return (catalog ?? []).find(d => d.name === '解决方案')?.categories ?? []
  }, [catalog])

  return (
    <div className={classes.wrapper}>

      {(categories ?? []).map((d, i) => (
        <Card
          refId={`aboutme_${d.name}`}
          key={i}
          title={d.name}
          text={d.description}
          image={d.icon}
          index={i}
          onClick={() => history(`/solution/${d.id}`)}
          id={d.id}
          isShowBtn={true}
        />
      ))}
    </div>
  );
}
