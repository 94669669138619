import React, { useContext, useMemo, useState } from "react";
import { Header, Catalog, Swiper } from "@/components";
import classes from "./Honor.module.css";
import { ReactComponent as ImageIcon } from "./img/image1.svg";
import { ReactComponent as Image2Icon } from "./img/image2.svg";
import ImageViewer from "react-simple-image-viewer";

import { CatalogContext } from "../../context";

const categories = [
  {
    "group": "所有",
    "horizontal": [
      "https://img1.biem.com.cn/image/honor/1-010.jpg",
      "https://img1.biem.com.cn/image/honor/1-011.jpg",
      "https://img1.biem.com.cn/image/honor/01.jpg",
      "https://img1.biem.com.cn/image/honor/02.jpg",
      "https://img1.biem.com.cn/image/honor/03.jpg",
      "https://img1.biem.com.cn/image/honor/04.jpg",
      "https://img1.biem.com.cn/image/honor/05.jpg",
      "https://img1.biem.com.cn/image/honor/06.jpg",
      "https://img1.biem.com.cn/image/honor/07.jpg",
      "https://img1.biem.com.cn/image/honor/08.jpg",
      "https://img1.biem.com.cn/image/honor/09.jpg",
      "https://img1.biem.com.cn/image/honor/10.jpg",
      "https://img1.biem.com.cn/image/honor/11.jpg",
      "https://img1.biem.com.cn/image/honor/12.jpg",
      "https://img1.biem.com.cn/image/honor/13.jpg",
      "https://img1.biem.com.cn/image/honor/14.jpg",
      "https://img1.biem.com.cn/image/honor/15.jpg",
      "https://img1.biem.com.cn/image/honor/16.jpg",
      "https://img1.biem.com.cn/image/honor/17.jpg",
      "https://img1.biem.com.cn/image/honor/18.jpg",
      "https://img1.biem.com.cn/image/honor/1-01.jpg",
      "https://img1.biem.com.cn/image/honor/1-02.jpg",
      "https://img1.biem.com.cn/image/honor/1-03.jpg",
      "https://img1.biem.com.cn/image/honor/1-031.jpg",
      "https://img1.biem.com.cn/image/honor/1-032.jpg",
      "https://img1.biem.com.cn/image/honor/1-033.jpg",
      "https://img1.biem.com.cn/image/honor/1-034.jpg",
      "https://img1.biem.com.cn/image/honor/1-035.jpg",
      "https://img1.biem.com.cn/image/honor/1-036.jpg",
      "https://img1.biem.com.cn/image/honor/1-037.jpg",
      "https://img1.biem.com.cn/image/honor/1-038.jpg",
      "https://img1.biem.com.cn/image/honor/1-039.jpg",
      "https://img1.biem.com.cn/image/honor/1-040.jpg",
      "https://img1.biem.com.cn/image/honor/1-041.jpg",
      "https://img1.biem.com.cn/image/honor/1-042.jpg",
      "https://img1.biem.com.cn/image/honor/1-043.jpg",
      "https://img1.biem.com.cn/image/honor/1-044.jpg",
      "https://img1.biem.com.cn/image/honor/1-045.jpg",
      "https://img1.biem.com.cn/image/honor/1-046.jpg",
      "https://img1.biem.com.cn/image/honor/1-047.jpg",
      "https://img1.biem.com.cn/image/honor/1-048.jpg",
    ],
    "vertical": [
      "https://img1.biem.com.cn/image/honor/1-09.jpg",
      "https://img1.biem.com.cn/image/honor/1-07.jpg",
      "https://img1.biem.com.cn/image/honor/1-08.jpg",
      "https://img1.biem.com.cn/image/honor/1-012.jpg",
      "https://img1.biem.com.cn/image/honor/1-013.jpg",
      "https://img1.biem.com.cn/image/honor/1-014.jpg",
      "https://img1.biem.com.cn/image/honor/1-015.jpg",
      "https://img1.biem.com.cn/image/honor/1-016.jpg",
      "https://img1.biem.com.cn/image/honor/1-017.jpg",
      "https://img1.biem.com.cn/image/honor/1-018.jpg",
      "https://img1.biem.com.cn/image/honor/1-019.jpg",
      "https://img1.biem.com.cn/image/honor/1-020.jpg",
      "https://img1.biem.com.cn/image/honor/1-021.jpg",
      "https://img1.biem.com.cn/image/honor/1-022.jpg",
      "https://img1.biem.com.cn/image/honor/1-023.jpg",
      "https://img1.biem.com.cn/image/honor/1-024.jpg",
      "https://img1.biem.com.cn/image/honor/1-025.jpg",
      "https://img1.biem.com.cn/image/honor/1-026.jpg",
      "https://img1.biem.com.cn/image/honor/1-027.jpg",
      "https://img1.biem.com.cn/image/honor/1-028.jpg",
      "https://img1.biem.com.cn/image/honor/1-029.jpg",
      "https://img1.biem.com.cn/image/honor/1-030.jpg",
      "https://img1.biem.com.cn/image/honor/1-049.jpg",
      "https://img1.biem.com.cn/image/honor/1-050.jpg",
      "https://img1.biem.com.cn/image/honor/1-051.jpg",
      "https://img1.biem.com.cn/image/honor/1-052.jpg",
      "https://img1.biem.com.cn/image/honor/1-04.jpg",
      "https://img1.biem.com.cn/image/honor/1-05.jpg",
      "https://img1.biem.com.cn/image/honor/1-06.jpg",
    ]
  },
  {
    "group": "发明专利",
    "horizontal": [
      "https://img1.biem.com.cn/image/honor/01.jpg",
      "https://img1.biem.com.cn/image/honor/02.jpg",
      "https://img1.biem.com.cn/image/honor/03.jpg",
      "https://img1.biem.com.cn/image/honor/04.jpg",
      "https://img1.biem.com.cn/image/honor/05.jpg",
      "https://img1.biem.com.cn/image/honor/06.jpg",
      "https://img1.biem.com.cn/image/honor/07.jpg",
      "https://img1.biem.com.cn/image/honor/08.jpg",
      "https://img1.biem.com.cn/image/honor/09.jpg",
      "https://img1.biem.com.cn/image/honor/10.jpg",
      "https://img1.biem.com.cn/image/honor/11.jpg",
      "https://img1.biem.com.cn/image/honor/12.jpg",
      "https://img1.biem.com.cn/image/honor/13.jpg",
      "https://img1.biem.com.cn/image/honor/14.jpg",
      "https://img1.biem.com.cn/image/honor/15.jpg",
      "https://img1.biem.com.cn/image/honor/16.jpg",
      "https://img1.biem.com.cn/image/honor/17.jpg",
      "https://img1.biem.com.cn/image/honor/18.jpg",
    ],
    "vertical": [
    ]
  },
  {
    "group": "获奖证书",
    "horizontal": [
      "https://img1.biem.com.cn/image/honor/1-010.jpg",
      "https://img1.biem.com.cn/image/honor/1-01.jpg",
      "https://img1.biem.com.cn/image/honor/1-02.jpg",
      "https://img1.biem.com.cn/image/honor/1-03.jpg",
      "https://img1.biem.com.cn/image/honor/1-011.jpg",
      "https://img1.biem.com.cn/image/honor/1-031.jpg",
      "https://img1.biem.com.cn/image/honor/1-032.jpg",
      "https://img1.biem.com.cn/image/honor/1-033.jpg",
      "https://img1.biem.com.cn/image/honor/1-034.jpg",
      "https://img1.biem.com.cn/image/honor/1-035.jpg",
      "https://img1.biem.com.cn/image/honor/1-036.jpg",
      "https://img1.biem.com.cn/image/honor/1-037.jpg",
      "https://img1.biem.com.cn/image/honor/1-038.jpg",
      "https://img1.biem.com.cn/image/honor/1-039.jpg",
      "https://img1.biem.com.cn/image/honor/1-040.jpg",
      "https://img1.biem.com.cn/image/honor/1-041.jpg",
      "https://img1.biem.com.cn/image/honor/1-042.jpg",
      "https://img1.biem.com.cn/image/honor/1-043.jpg",
      "https://img1.biem.com.cn/image/honor/1-044.jpg",
      "https://img1.biem.com.cn/image/honor/1-045.jpg",
      "https://img1.biem.com.cn/image/honor/1-046.jpg",
      "https://img1.biem.com.cn/image/honor/1-047.jpg",
      "https://img1.biem.com.cn/image/honor/1-048.jpg",
       
     
    ],
    "vertical": [
      "https://img1.biem.com.cn/image/honor/1-07.jpg",
      "https://img1.biem.com.cn/image/honor/1-08.jpg",
      "https://img1.biem.com.cn/image/honor/1-09.jpg",
      "https://img1.biem.com.cn/image/honor/1-012.jpg",
      "https://img1.biem.com.cn/image/honor/1-013.jpg",
      "https://img1.biem.com.cn/image/honor/1-014.jpg",
      "https://img1.biem.com.cn/image/honor/1-015.jpg",
      "https://img1.biem.com.cn/image/honor/1-016.jpg",
      "https://img1.biem.com.cn/image/honor/1-017.jpg",
      "https://img1.biem.com.cn/image/honor/1-018.jpg",
      "https://img1.biem.com.cn/image/honor/1-019.jpg",
      "https://img1.biem.com.cn/image/honor/1-020.jpg",
      "https://img1.biem.com.cn/image/honor/1-021.jpg",
      "https://img1.biem.com.cn/image/honor/1-022.jpg",
      "https://img1.biem.com.cn/image/honor/1-023.jpg",
      "https://img1.biem.com.cn/image/honor/1-024.jpg",
      "https://img1.biem.com.cn/image/honor/1-025.jpg",
      "https://img1.biem.com.cn/image/honor/1-026.jpg",
      "https://img1.biem.com.cn/image/honor/1-027.jpg",
      "https://img1.biem.com.cn/image/honor/1-028.jpg",
      "https://img1.biem.com.cn/image/honor/1-029.jpg",
      "https://img1.biem.com.cn/image/honor/1-030.jpg",
      "https://img1.biem.com.cn/image/honor/1-049.jpg",
      "https://img1.biem.com.cn/image/honor/1-050.jpg",
      "https://img1.biem.com.cn/image/honor/1-051.jpg",
      "https://img1.biem.com.cn/image/honor/1-052.jpg",
      "https://img1.biem.com.cn/image/honor/1-04.jpg",
      "https://img1.biem.com.cn/image/honor/1-05.jpg",
      "https://img1.biem.com.cn/image/honor/1-06.jpg",
    ]
  }


]



export default function Honor() {
  const { catalog } = useContext(CatalogContext);

  const [horizontal, setHorizotal] = useState(categories[0].horizontal)
  const [vertical, setVertical] = useState(categories[0].vertical)

  const [currentCategory, setCurrentCategory] = useState(0)

  const cataList = useMemo(() => {
    var list = []
    categories.forEach((category, idx) => {
      list.push({"id": idx, "name": category.group})
    })
    return list
  }, [catalog]);

  const [currentImage, setCurrentImage] = useState("0");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  const openImageViewer = (list, idx) => {
    setImages(list);
    setCurrentImage(idx);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const changeCategory = (idx) => {
    setHorizotal(categories[idx].horizontal)
    setVertical(categories[idx].vertical)
    setCurrentCategory(idx)
  }

  return (
    <div className={classes.wrapper}>
      <Header title="荣誉资质" desc="" />
      <Catalog 
        list={cataList}
        onChange={changeCategory}
        current={currentCategory}
        isShowInput={false} />
      <div className={classes.content}>
        <div className={classes.top}>
          <div className={classes.item}>
            <ImageIcon className={classes.rect} />
            <div className={classes.c1}>
              <div className={classes.name}>80+</div>
              <div className={classes.t1}>国家、国际等奖励</div>
            </div>
            <Image2Icon className={classes.rect} />
          </div>
          <div className={classes.item}>
            <ImageIcon className={classes.rect} />
            <div className={classes.c1}>
              <div className={classes.name}>160+</div>
              <div className={classes.t1}>授权专利</div>
            </div>
            <Image2Icon className={classes.rect} />
          </div>
        </div>
        <div className={classes.slice1}>
          {horizontal.length >0 && (
              <Swiper list={horizontal} count={3} gap={40}>
              {(item, index) => (
                <div>
                <img
                  onClick={() => {
                    console.log("click index", index)
                    openImageViewer(horizontal, index);
                  }}
                  className={classes.image1}
                  src={`${item}?x-oss-process=style/490`}
                  key={index}
                />
                </div>
              )}
              </Swiper>
          )}
         
        </div>
        <div className={classes.slice2}>
          {vertical.length >0  && (
            <Swiper list={vertical} count={3} gap={40}>
            {(item, index) => (
              <img
                onClick={() => {
                  openImageViewer(vertical, index);
                }}
                className={classes.image2}
                src={`${item}?x-oss-process=style/700`}
                key={index}
              />
            )}
          </Swiper>
          )}
        
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            "background-color": "rgb(0 0 0 / 87%)",
            zIndex: 9999,
          }}
        />
      )}
    </div>
  );
}
