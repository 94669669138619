import React from "react";
import classes from "./Modal.module.css";
import { ReactComponent as Forward1Icon } from "assets/forward1.svg";
import { ReactComponent as ExpendIcon } from "assets/expend.svg";

export default function Modal(props) {
  const { isShow, data, index, left, right, onPre, onNext, onHidden } = props;

  if (!isShow) return null;
  return (
    <div className={classes.modal}>
      <div className={classes.content}>
        <div className={classes.date}>{data?.year}</div>
        <div className={classes.name}>{data?.label}</div>
        <div className={classes.text}>{data?.title}</div>
        <img className={classes.image} src={data?.cover} />
        <div className={classes.descs}>
          {(data?.content || []).map((d) => (
            <div className={classes.desc}>{d}</div>
          ))}
        </div>
        
      </div>

      {left && (
        <div className={ `${classes.left} ${classes.button}` } onClick={onPre} style={{ textAlign: "right" }}>
          <Forward1Icon
            className={classes.icon}
            
            style={{ transform: "rotate(180deg)" }}
          />
          <div className={classes.year1}>{left?.year}</div>
          <div className={classes.lable1}>{left?.label}</div>
        </div>
      )}

      {right && (
        <div className={`${classes.right} ${classes.button}`} onClick={onNext}>
          <Forward1Icon className={classes.icon}  />
          <div className={classes.year1}>{right.year}</div>
          <div className={classes.lable1}>{right.label}</div>
        </div>
      )}
      <ExpendIcon className={classes.expend} onClick={onHidden} />
    </div>
  );
}
