import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CatalogContext } from "../../context";
import classes from "./Links1.module.css";
import { useState, useMemo, useRef } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { list, newList } from "./const";
import { fetchAboutMe } from "../../api";
import { useApi } from "../../hook";
import HistoryURL from "./img/history.png";
import HoronURL from "./img/horon.png";
import IamgePng from "assets/image1.png";

export default function Links() {
  const location = useLocation();
  const [cur, setCur] = useState({});

  const { catalog } = useContext(CatalogContext);

  const [aboutList] = useApi(fetchAboutMe);

  const current = useMemo(() => {
    return `/${location.pathname.split("/")[1]}`;
  });

  const cataMap = useMemo(() => {
    const map = (catalog ?? []).reduce(
      (pre, cur) => ({ ...pre, [cur.name]: cur.categories }),
      {}
    );
    map["人才招聘"]=[]
    map["解决方案"] = []

    map["走进我们"] = [
      ...(aboutList ?? []).map((d) => ({
        name: d.title,
        id: d.category_id,
        icon: d.cover,
      })),
      {
        name: "发展历程",
        id: "",
        icon: HistoryURL,
        path: "/history",
      },
      {
        name: "荣誉资质",
        id: "",
        icon: HoronURL,
        path: "/honor",
      },
    ];
    return map;
  }, [catalog, aboutList]);

  return (
    <div className={classes.links}>
      {newList.map((d, i) => (
        <div
          key={i}
          className={cx(classes.link, {
            [classes.selected]: current === d.path,
          })}
          onMouseEnter={() => setCur(d)}
          onMouseLeave={() => setCur({})}
        >
          {cur.name !== d.name && (
            <Link style={d.style} to={d.path}>
              {d.name}
            </Link>
          )}
          {current === d.path && <div className={classes.divier}></div>}
          {d.name === cur.name && (
            <div className={classes.dropMenu}>
              <DropDown2
                d={d}
                active={current === d.path}
                list={cataMap[d.name]}
                isShow={false}
              />
            </div>
          )}
        </div>
      ))}
      {newList.map((d) => (
        <>
          {d.name === cur.name && (
            <DropDown2
              d={d}
              active={current === d.path}
              list={cataMap[d.name]}
              isShowTitle={false}
              isShow={d.isHidden? false: true}
              onMouseEnter={() => setCur(d)}
              onMouseLeave={() => setCur({})}
            />
          )}
        </>
      ))}

      <div className={cx(classes.link, classes.contact)}>
        <Link to={"/contact"}>联系我们</Link>
      </div>
    </div>
  );
}

function DropDown2(props) {
  const {
    list,
    d,
    active,
    isShow = true,
    isShowTitle = true,
    onMouseLeave = () => {},
    onMouseEnter = () => {},
  } = props;
 
  const downRef = useRef();
  const itemRef = useRef();
  const nav = useNavigate();
  const location = useLocation();
  const [width, setWidth] = useState(0);
  

  const gotoTarget = (value) => {
    const dom = document.getElementById(`${d.key}_${value.name}`);
    dom.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const onLink = (value) => {
    onMouseLeave()
    if (d.key === "aboutme") {
      if (value.path) {
        nav(value.path);
        return;
      }
      nav(d.path, {state: {target: value }});
      return;
    }

    if (d.key === "news" || d.key === "cooperation" || d.key === "jobs") {
      nav(`${d.path}?categoryId=${value.id}`, {state: {target: "catalog-nav"}});
      return;
    }
    nav(d.path + "/" + value.id);
  };

  useEffect(() => {
    if (!itemRef.current) return;
    setWidth(itemRef.current?.offsetWidth);
  }, [itemRef]);
  return (
    <>
      <div className={classes.dropDown2}>
        {isShowTitle && (
          <div
            className={classes.dropDown2Name}
            style={{ fontWeight: active ? 600 : 400 }}
            onClick={() => {
              if(d.key === 'solution') {
                nav(d.path + "/" + 129);
                return;
              }
              if (d.key === "aboutme" || d.key === "jobs" || d.key === "news" || d.key === "cooperation" || d.key ==='solution') {
                nav(d.path);
              }
            }}
          >
            {d.name}
            {active && <div className={classes.divier}></div>}
          </div>
        )}
      </div>
      {isShow && list?.length > 0 && (
        <div
          ref={downRef}
          id="dropdown-abc"
          className={classes.dropDown2Menu}
          style={{
            gridTemplateColumns: `repeat(${Math.min(
              5,
              list?.length ?? 0
            )}, 1fr)`,
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {(list ?? []).map((d, i) => (
            <div
              ref={itemRef}
              key={i}
              className={classes.dropItem}
              onClick={() => onLink(d)}
            >
              <img
                className={classes.dropImage}
                src={d.icon || IamgePng}
                style={{
                  height: width > 0 ? `${(width * 200) / 336}px` : "",
                }}
              />
              <div className={classes.dropText}>{d.name}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
