import React, { useEffect } from "react";
import classes from "./Detail.module.css";
import { ReactComponent as NavArrow } from "assets/navArrow.svg";
import { fetchJobDetail } from "../../api";
import { useApi, useDevice } from "../../hook";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import events from "@/event";
import { Editor } from "../../components";

export default function Detail() {
  const { id = "" } = useParams();
  const nav = useNavigate();
  const [data] = useApi(fetchJobDetail, id);

  const { isH5 } = useDevice();

  useEffect(() => {
    if (!data) return;
    events.emit("catalog", data.title);

    return () => {
      events.emit("catalog", "");
    };
  }, [data]);

  const formatData = (ts) => {
    return dayjs.unix(ts).format("YYYY年MM月DD日");
  };

  const gotoPage = () => {
    nav(-1);
  };

  if (!data) return null;
  return (
    <div className={classes.wrapper}>
      <div className={classes.head}>
        <div className={classes.catalog}>正在热招</div>
        {isH5 && (
          <div className={classes.date}>{formatData(data.published_at)}</div>
        )}
        <div className={classes.title}>{data.title}</div>
        <div className={classes.divder} style={{ marginBottom: "26px" }}></div>
        {!isH5 && (
          <div className={classes.date}>{formatData(data.published_at)}</div>
        )}
        <div className={classes.back} onClick={gotoPage}>
          <NavArrow
            className={classes.backIcon}
            style={{ transform: "rotate(180deg)" }}
          />
          返回搜索结果页
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.label}>工作地点：</div>
        <div>{data.address || "-"}</div>
        <div className={classes.label}>工作性质：</div>
        <div>{data.job_type || "-"}</div>
        <div className={classes.label}>性别要求：</div>
        <div>{data.sex_type || "-"}</div>
        <div className={classes.label}>薪金范围：</div>
        <div>{data.salary || "-"}</div>
        <div className={classes.label}>工作经验：</div>
        <div>{data.experience || "-"}</div>
        <div className={classes.label}>年龄要求：</div>
        <div>{data.age || "-"}</div>
      </div>
      <div
        className={classes.divder}
        style={{ marginBottom: "24px", marginTop: "24px" }}
      ></div>
      <div className={classes.content1}>
        <div className={classes.contentTitle}>任职资格</div>
        <Editor className={classes.text} data={data.qualifications} />
        <div className={classes.contentTitle}>岗位职责</div>
        <Editor className={classes.text} data={data.responsibility} />
      </div>
      <div
        className={classes.divder1}
        style={{ marginBottom: "35px", marginTop: "35px" }}
      ></div>
      <div className={classes.footer} style={{ textAlign: "center" }}>
        请于 {formatData(data.deadline_at)} 前，将简历投递至:{" "}
        <span style={{ color: "#2C60F5" }}>{data.email} </span>
      </div>
    </div>
  );
}
