import React from "react";
import classes from "./AboutMeDetail.module.css";
import { fetchAboutMeDetail } from "../../api";
import { useApi } from "../../hook";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import events from "@/event";
import commonCss from "@/App.module.css";
import { Editor } from "../../components";

export default function AboutMeDetail() {
  const { id = "" } = useParams();
  const [data] = useApi(fetchAboutMeDetail, id);

  useEffect(() => {
    if (!data) return;
    events.emit("catalog", data.title);

    return () => {
      events.emit("catalog", "");
    };
  }, [data]);

  if (!data) return null;
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{data.title}</div>
      <div className={classes.divder}></div>
      {/* <div className={classes.desc}>{data.sub_title}</div>
      <img src={data.cover} className={classes.image} /> */}
      <Editor className={classes.content} data={data.content} />
    </div>
  );
}
