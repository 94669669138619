

const list = [
  {
    key: "aboutme",
    path: "/about",
    name: "走进我们",
    type: 2,
  },
  {
    path: "/news",
    key: "news",
    name: "新闻资讯",
    type: 1,
  },
  {
    path: "/solution",
    key: "solution",
    name: "解决方案",
    type: 2,
  },
  {
    path: "/product",
    key: 'product',
    name: "产品技术",
    type: 2,
  },
  {
    path: "/cooperation",
    key: "cooperation",
    name: "合作交流",
    type: 1,
  },
  {
    path: "/jobs",
    key: 'jobs',
    name: "人才招聘",
    type: 2,
    isHidden: true
  },

];

const list2 = [{
  path: "/solution",
  key: "solution",
  name: "解决方案",
  type: 2,
},
{
  path: "/product",
  key: 'product',
  name: "产品技术",
  type: 2,
}, {
  key: "aboutme",
  path: "/about",
  name: "走进我们",
  type: 2,
}]


const newList = [
  {
    key: "aboutme",
    path: "/about",
    name: "走进我们",
  },
  {
    path: "/news",
    key: "news",
    name: "新闻资讯",
  },
  {
    path: "/solution",
    key: "solution",
    name: "解决方案",
  },
  {
    path: "/product",
    key: 'product',
    name: "产品技术",
  },
  {
    path: "/cooperation",
    key: "cooperation",
    name: "合作交流",
  },
  {
    path: "/jobs",
    key: 'jobs',
    name: "人才招聘",
    isHidden: true,
  },

];


export {
  list,
  list2,
  newList
}