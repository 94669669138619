import React, { useEffect, useMemo, useState } from "react";
import classes from "./Detail.module.css";
import { Header, Catalog, Swiper } from "@/components";
import { useApi } from "../../hook";
import useQuery from "../../hook/useQuery";
import { throttle, debounce } from "lodash";
import { BsPlayBtnFill } from "react-icons/bs";
import { ModalVideo } from "@/components";

import { useDevice } from "../../hook/useDevice";
import { fetchSolutionDetail, fetchProductDetail } from "../../api";
import { useParams } from "react-router-dom";
import events from "@/event";


export default function Detail() {
  const { id = "" } = useParams();
  const [data] = useApi(fetchSolutionDetail, id);

  const [currentSection, setCurrentSection] = useState(0);

  let sections = [];

  const arr = useMemo(() => {
    return (data?.content ?? []).map((d, i) => ({
      id: i,
      name: d.title,
      key: d.title,
    }));
  }, [data]);

  console.log("arr", arr);

  const { isH5 } = useDevice();

  const background = isH5 ? "rgba(44, 96, 245, 0.05)" : "#F8F9FA";

  const showMessagePanel = () => {
    window._MEIQIA("init");
    window._MEIQIA("showPanel");
  };

  const goToProductDetailPage = (data) => {
    window.open(`/product/detail/${data.id}`);
  };

  useEffect(() => {
    if (!data) return;
    events.emit("catalogObj", [
      { path: `/solution/${data.parent_id}`, name: data.parent_name },
      { path: "", name: data.title },
    ]);

    return () => {
      events.emit("catalogObj", []);
    };
  }, [data]);

  const activeSection = (idx) => {
    // setCurrentSection(idx)
    const dom = document.getElementById(`section-${idx}`);
    dom.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handlerScrool, true);
    return () => window.removeEventListener("scroll", handlerScrool, true);
  });

  const handlerScrool = throttle((e) => {
    let activeIndex = 0;
    let miniTop = 999999;
    sections.forEach((s, idx) => {
      const clientRect = document.getElementById(s).getBoundingClientRect();
      let top = Math.abs(clientRect.top);
      if (top < miniTop) {
        miniTop = top;
        activeIndex = idx;
      }
    });
    setCurrentSection(activeIndex);
  }, 100);

  const [videomModal, setVideomModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");

  const openVideoModal = (src) => {
    setVideoSrc(src);
    setVideomModal(!videomModal);
  };

  const closeVideoMoal = () => {
    setVideomModal(!videomModal);
  };

  if (!data) return null;

  return (
    <div className={classes.wrapper}>
      {videomModal && <ModalVideo onClose={closeVideoMoal} src={videoSrc} />}

      <Header
        onClick={showMessagePanel}
        title={data.title}
        desc={data.sub_title}
        cover={data.head}
      />
      <Catalog
        list={arr}
        onChange={activeSection}
        current={currentSection}
        isShowInput={false}
      ></Catalog>

      <div className={classes.content}>
        {(data?.content ?? []).map((d, i) => (
          <>
            <div style={{ display: "none" }}>
              {" "}
              {sections.push(`section-${i}`)}
            </div>
            {d.type === "more" ? (
              <Card2
                id={`section-${i}`}
                openVideoModal={openVideoModal}
                d={d}
                background={i % 2 === 0 ? "" : background}
                direction={i % 2 === 0 ? "lrt": "rtl"}
              />
            ) : (
              <Card1
                id={`section-${i}`}
                openVideoModal={openVideoModal}
                d={d}
                background={i % 2 === 0 ? "" : background}
              />
            )}
          </>
        ))}
      </div>
      {data.relation_product.length > 0 && (
        <div className={classes.card3}>
          <div className={classes.title}>相关产品</div>
          <div className={classes.items}>
            <Swiper list={data.relation_product ?? []} count={4} gap={24}>
              {(d, i) => (
                <div
                  className={classes.item}
                  onClick={() => {
                    goToProductDetailPage(d);
                  }}
                >
                  <img className={classes.image5} src={d.cover} />
                  <div className={classes.name5}>{d.name}</div>
                </div>
              )}
            </Swiper>
          </div>
        </div>
      )}

      <div className={classes.card6}>
        <div className={classes.content6}>
          <div className={classes.title6}>对这个方案感兴趣吗？</div>
          <div className={classes.desc6}>
            不确定方案是否可以解决你的问题，或者想了解更多细节，随时告诉我们。
          </div>
          <div className={classes.btn6}>
            <div className={classes.button6} onClick={showMessagePanel}>
              联系我们
            </div>
            {data.pdf && (
              <div
                className={classes.button7}
                onClick={() => {
                  window.open(data.pdf);
                }}
              >
                下载 PDF
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Card2(props) {
  const { background = "", direction= "", d, openVideoModal } = props;
  return (
    <div id={props.id} className={classes.card2} style={{ background, direction }}>
      <div className={classes.label}>{d.title}</div>
      
      <CardDetail
          openVideoModal={openVideoModal}
          d={d}
        />
        <div className={classes.desc}>
        <span style={{fontWeight: 500, color: "black"}}>{d.description}</span>
      </div>
    </div>
  );
}

function CardDetail(props) {
  const {
    background = "",
    d,
    title = "",
    text = "",
    image = "",
    openVideoModal,
  } = props;
  return (
    <div className={classes.card} style={{ background }}>
      <div className={classes.left}>
      {(d.sub_sections ?? []).map((p, i) => (
        <div className={classes.line}> 
         <span className={classes.name}>{p.title}</span>
        <span className={classes.text}>{p.description}</span>
        </div>
      ))}
       
      </div>
      {d.sub_sections && (
        <Media
          d={d.sub_sections[0].media}
          openVideoModal={openVideoModal}
          className={classes.right}
        />
      )}
      
    </div>
  );
}

function Media(props) {
  const { d, className, openVideoModal } = props;
  return (
    <>
      {d.type === "image" ? (
        <img src={d.url} className={className} />
      ) : (
        <div className={classes.videoContainer}>
          <video className={className} controls={false} loop autoPlay muted>
            <source src={d.url} type="video/mp4" />
          </video>
          <BsPlayBtnFill
            className={classes.openVideo}
            onClick={() => {
              openVideoModal(d.url);
            }}
          />
        </div>
      )}
    </>
  );
}
function Card1(props) {
  const { background = "", d, openVideoModal } = props;
  return (
    <div id={props.id} className={classes.card2} style={{ background }}>
      <div className={classes.label}>{d.title}</div>
      <div className={classes.desc}>
        <span>{d.description}</span>
      </div>
      <Media
        d={d.media}
        className={classes.image1}
        openVideoModal={openVideoModal}
      />
    </div>
  );
}
