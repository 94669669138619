import React, { useMemo } from "react";
import classes from "./Swiper.module.css";
import { useState, useEffect, useRef } from "react";
import { ReactComponent as LeftIcon } from "./Left.svg";
import { ReactComponent as RightIcon } from "./Right.svg";
import { useDevice } from "../../hook";

import { Splide, SplideSlide } from "@splidejs/react-splide";

const colors = {
  black: {
    active: "rgba(0, 0, 0)",
    normal: "rgba(0, 0, 0, 0.2)",
  },
  white: {
    active: "#FFFFFF",
    normal: "rgba(255, 255, 255, 0.2)",
  },
};

function pxToRem(n) {
  var docEl = document.documentElement;
  const fontSize = docEl.style.fontSize.replace("px", "");

  console.log();
  return n / (fontSize ?? 1);
}

export default function Swiper(props) {
  const {
    list,
    count = 1,
    showDot = true,
    color = "black",
    mode = "normal",
  } = props;
  const sliceRef = useRef();

  const len = (list || []).length;

  const { isH5 } = useDevice();

  const dots = useMemo(() => {
    return new Array(Math.ceil(len / count)).fill(0);
  }, [len, count]);

  const [sort, setSort] = useState(0);
  const [conWidth, setConWidth] = useState(0);

  useEffect(() => {
    if (!sliceRef.current) return;
    setConWidth(sliceRef.current.clientWidth);
  }, []);

  const onPre = () => {
    setSort(Math.max(sort - 1, 0));
  };
  const onNext = () => {
    setSort(Math.min(sort + 1, dots.length - 1));
  };

  const changeDot = (idx) => {
    setSort(Math.min(idx, dots.length - 1));
  };

  const splideOptions = {
    drag: "free",
    focus: "first",
    snap: true,
    // perPage: window.innerWidth / (isH5 ? 130 : 250),
    autoScroll: {
      speed: 1,
    },
    pagination: false,
    arrows: false,
  };

  if (isH5 && mode === "splide") {
    return (
      <Splide
        aria-label="My Favorite Images"
        // extensions={{ AutoScroll }}
        options={splideOptions}
      >
        {list.map((d, index) => (
          <SplideSlide>
            <>
            {props.children && props.children(d, index)} 
            </>
          </SplideSlide>
        ))}
      </Splide>
    );
  }

  if (isH5 && mode === "normal") {
    return (
      <div className={classes.swipers}>
        {list.map((d, index) => (
          <>{props.children && props.children(d, index)}</>
        ))}
      </div>
    );
  }

  return (
    <div ref={sliceRef} className={classes.slices}>
      <div className={classes.slice}>
        <div
          className={classes.items}
          style={{ transform: `translateX(-${pxToRem(conWidth) * sort}rem)` }}
        >
          {dots.map((d, i) => (
            <div
              className={classes.contentItems}
              style={{
                width: `${conWidth}px`,
                // gap: `${gap / 75}rem`,
              }}
            >
              {list
                .slice(count * i, count + count * i)
                .map((data, index) => props.children(data, count * sort+ index))}
            </div>
          ))}
        </div>
      </div>

      <div
        className={`${classes.right} ${classes.button}`}
        style={{ marginLeft: "57px" }}
        onClick={onNext}
      >
        <LeftIcon className={classes.arrow} />
      </div>
      <div
        className={`${classes.left} ${classes.button}`}
        style={{ marginRight: "57px" }}
        onClick={onPre}
      >
        <RightIcon className={classes.arrow} />
      </div>
      {showDot && (
        <div className={classes.dots}>
          {dots.map((dot, i) => (
            <span
              className={classes.dot}
              onClick={() => {
                changeDot(i);
              }}
              style={{
                background:
                  sort === i ? colors[color].active : colors[color].normal,
              }}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
}
