import React from "react";
import BgUrl from "assets/bg3.svg";
import classes from "./Header.module.css";
import cx from "classnames";

export default function Header(props) {
  const { title, desc, cover = "" } = props;
  return (
    <div
      className={cx(classes.header, { [classes.header1]: !!cover })}
      style={{ backgroundImage: `url(${cover || BgUrl})` }}
    >
      <div className={classes.image}>
        <div className={classes.title1}>{title}</div>
        <div className={classes.title2}>{desc}</div>
      </div>

      {cover && <div className={classes.mask}></div>}
    </div>
  );
}
