import React, { useEffect } from "react";
import classes from "./Contact.module.css";
import { Header } from "@/components";
import { useState } from "react";
import cx from "classnames";
import { ContactForm } from "@/components";
import AMapLoader from "@amap/amap-jsapi-loader";

export default function Contact() {
  useEffect(() => {
    AMapLoader.load({
      key: "b115a889a0996dfdf5419c6bb4806277", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
      .then((AMap) => {
        this.map = new AMap.Map("map", {
          //设置地图容器id
          viewMode: "3D", //是否为3D地图模式
          zoom: 18, //初始化地图级别
          center: [116.365714, 39.977505], //初始化地图中心点位置
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className={classes.wrapper}>
      <Header
        title="联系我们"
        desc="报价请求、客户支持、技术培训，有需要随时让我们知道。"
      />
      <div className={classes.content}>
        <div className={classes.map} id="map"></div>
        <PhoneWay />
        {/* <div className={classes.divider}></div>
        <div className={classes.title1}>取得联系</div>
        <ContactForm /> */}
      </div>
    </div>
  );
}
const titles = {
  company: {
    title: "公司信息",
    list: [
      {
        name: "公司名称",
        text: "北京市电加工研究所有限公司",
      },
      {
        name: "办公地址",
        text: "北京市海淀区花园路 B3 号迪蒙大厦",
      },
      {
        name: "联系电话",
        text: "010-62017490 18610881234  17600028372",
        bold: true,
      },
      {
        name: "传真电话",
        text: "010-62022375",
        bold: true,
      },
    ],
  },
  d: {
    title: "产品销售",
    list: [
      {
        name: "联系人",
        text: "方先生",
      },
      {
        name: "联系电话",
        text: "010-62017490",
        bold: true,
      },
      {
        name: "手机号码",
        text: "18610881234",
        bold: true,
      },
    ],
  },
};
function PhoneWay() {
  const [cur, setCur] = useState("company");

  return (
    <div>
      <div className={classes.title}>
        {Object.keys(titles).map((key, i) => (
          <div
            key={i}
            className={cx({ [classes.active]: key === cur })}
            style={{ cursor: "pointer" }}
            onClick={() => setCur(key)}
          >
            {titles[key].title}
            {/* <div className={classes.activeH}></div> */}
          </div>
        ))}
      </div>
      <div className={classes.divider}></div>
      <div className={classes.items}>
        {(titles[cur]?.list || []).map((d, i) => (
          <div key={i} className={classes.item}>
            <div className={classes.name}>{d.name}</div>
            <div className={cx(classes.text, { [classes.bold]: d.bold })}>
              {d.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
