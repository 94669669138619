import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { CatalogContext } from "../../context";
import classes from "./Navs.module.css";
import { useState, useMemo, useRef } from "react";
import cx from "classnames";
import { ReactComponent as NavArrowIcon } from "assets/navArrow.svg";
import { list } from "./const";
import { useNavigate } from "react-router-dom";

export default function Navs(props) {
  const { onNavChange } = props;
  const location = useLocation();
  const [cur, setCur] = useState("");
  const nav = useNavigate();

  const { catalog } = useContext(CatalogContext);

  const current = useMemo(() => {
    return `/${location.pathname.split("/")[1]}`;
  });

  const cataMap = useMemo(() => {
    const map = (catalog ?? []).reduce(
      (pre, cur) => ({ ...pre, [cur.name]: cur.categories }),
      {}
    );

    map["走进我们"] = [
      ...(map["走进我们"] ?? []),
      {
        name: "发展历程",
        id: "",
        icon: "",
        path: "/history",
      },
      {
        name: "荣誉资质",
        id: "",
        icon: "",
        path: "/honor",
      },
    ];
    return map;
  }, [catalog]);

  const gotoTarget = (d, value) => {
    const dom = document.getElementById(`${d.key}_${value.name}`);
    dom.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const gotoPage = (d, cata) => {
    if (d.key === "news" || d.key === "cooperation" || d.key === "jobs") {
      nav(`${d.path}?categoryId=${cata.id}`);
      onNavChange();
      return;
    }
    if (d.key === "solution" || d.key === "product") {
      nav(`${d.path}/${cata.id}`);
      onNavChange();
      return;
    }
    if (cata.path) {
      nav(cata.path);
      onNavChange();
      return;
    }
    if (location.pathname === d.path) {
      gotoTarget(d, cata);
    } else {
      nav(d.path);
      setTimeout(() => {
        gotoTarget(d, cata);
      }, 500);
    }
    onNavChange();
  };

  return (
    <div className={classes.navs}>
      <div className={classes.items}>
        {list.map((d) => (
          <div className={classes.card}>
            <div
              className={cx(classes.item, { [classes.active]: cur === d.name })}
              onClick={() => {
                if (cur === d.name) {
                  setCur("");
                } else {
                  setCur(d.name);
                }
              }}
            >
              {d.name}
              {cur === d.name && <NavArrowIcon className={classes.arrow} />}
            </div>
            <div
              className={cx(classes.content, {
                [classes.contentActive]: cur === d.name,
              })}
            >
              <div className={classes.content1}>
                {(cataMap[d.name] ?? []).map((cata) => (
                  <div
                    className={classes.nav}
                    onClick={() => gotoPage(d, cata)}
                  >
                    {cata.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
