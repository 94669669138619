import React, { useContext, useMemo,useEffect } from "react";
import classes from "./Recruit.module.css";
import { ReactComponent as NavArrow } from "assets/navArrow.svg";
import { Header, Catalog } from "@/components";
import { fetchJobs } from "../../api";
import { usePageApi } from "../../hook";
import useQuery from "../../hook/useQuery";
import { CatalogContext } from "../../context";
import useQuerySetter from "../../hook/useQuerySetter";
import { Empty, Pagination, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useLocation } from 'react-router-dom';


export default function Recruit() {
  const { offset, title, categoryId = "", isHot = false } = useQuery();
  const setQuery = useQuerySetter();
  const { catalog } = useContext(CatalogContext);
  const history = useNavigate();
  const location = useLocation();


  const params = useMemo(() => {
    return {
      title,
      category_id: categoryId,
      limit: 10,
      offset,
      is_hot: isHot,
    };
  }, [title, offset, categoryId, isHot]);


  const onCatalogChange = (id) => {
    setQuery({
      categoryId: id,
    });
  };
  const onChange = (id) => {
    setQuery({
      offset: id - 1,
    });
  };
  const onInputChange = (value) => {
    setQuery({
      title: value,
    });
  };
  const onIsHotChange = (value) => {
    setQuery({
      isHot: value,
    });
  };

  const [data] = usePageApi(fetchJobs, params, true);
  useEffect(()=> {
    document.getElementById("main").scrollTop = 10
  }, [data])

  const isEmpty = (data?.data || []).length < 1;

  if (!data) return null;

  return (
    <div className={classes.wrapper}>
      <Header title="人才招聘" desc="加入 BIEM，展示自我。" />
      <Catalog
        current={"all"}
        list={[{ name: "所有", id: "all" },]}
        onChange={onCatalogChange}
        onInputChange={onInputChange}
      >
        <div className={classes.switch}>
          <div>只看热招中</div>
          <Switch defaultChecked={isHot} onChange={onIsHotChange} />
        </div>
      </Catalog>

      <div className={classes.content}>
        {(data.data ?? []).map((d, i) => (
          <Card
            key={i}
            d={d}
            onClick={() => {
              history(`/jobs/${d.id}`);
            }}
          />
        ))}
        {isEmpty && <Empty description={"没有匹配到数据"} />}

        {!isEmpty && (
          <div className={classes.page}>
            <Pagination
              current={Number(offset) + 1}
              onChange={onChange}
              total={data?.paging?.total || ""}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function Card(props) {
  const { d, onClick } = props;

  return (
    <div className={classes.card}>
      <div className={classes.left}>
        <div className={classes.text1}>正在热招</div>
        <div className={classes.text2}>{d.title}</div>
        <div className={classes.text3}>{d.category_name}</div>
        <div className={classes.text4}>
          {dayjs.unix(d.published_at).format("YYYY年MM月DD日")}
        </div>
        <div className={classes.text5} onClick={onClick}>
          职位详情
          <NavArrow className={classes.icon} />
        </div>
      </div>
      {d.cover && <img className={classes.image} src={d.cover} />}
    </div>
  );
}
