import React, { useEffect } from "react";
import { useState } from "react";
import classes from "./Header.module.css";
import { ReactComponent as DetailButtonIcon } from "assets/detailButton.svg";
import { useRef } from "react";
import { ModalVideo } from "@/components";
import { BsPlayBtnFill } from "react-icons/bs";
import { ReactComponent as LeftIcon } from "./img/left.svg";
import { ReactComponent as RightIcon } from "./img/right.svg";

import { Progress } from 'antd';

export default function Header(props) {
  const { list = [] } = props;
  const [index, setIndex] = useState(-1);
  const [conStyle, setConStyle] = useState({});
  const contanerRef = useRef();
  const [conWidth, setConWidth] = useState(0);
  const [isFirst, setisFirst] = useState(true);

  const [videomModal, setVideomModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [percent, setPercent] = useState(0)

  var timer = null
  var progressTimer = null

  useEffect(() => {
    if (!contanerRef.current) return;
    setConWidth(contanerRef.current.clientWidth);
  }, []);

  useEffect(() => {
    if (isFirst) {
      showNextFrame();
      setisFirst(false);
    }
  });

  const openVideoModal = (src) => {
    setVideoSrc(src);
    setVideomModal(!videomModal);
  };

  const closeVideoMoal = () => {
    setVideomModal(!videomModal);
  };

  const onTransitionEnd = () => {
    if ((list ?? [])[index]?.media_type === 1) {
      const dom = document.getElementById(`video-${index}`);
      dom.play();
    } else {
      timer = setTimeout(() => {
        showNextFrame();
      }, 20 * 1000);
    }
  };

  const cleanCurrenFrmeAction = () => {
    setPercent(0)
    if ((list ?? [])[index]?.media_type === 1) {
      const dom = document.getElementById(`video-${index}`);
      dom.pause();
    } else {
      clearTimeout(timer)
      clearTimeout(progressTimer)
    }
  }
  const beginImageFrameProgressTimer = ()=> {
    if (list[index]?.media_type === 0) {
      var current = 0
      progressTimer  = setTimeout(() => {
        setPercent(current / 15 * 100)
        current++
      }, 1000);
    }
  }
  
  const showNextFrame = () => {
    cleanCurrenFrmeAction()
    const newIndex = (index + 1) % (list ?? []).length;
    setIndex(newIndex);
    setConStyle({
      transform: `translateX(-${newIndex * conWidth}px)`,
      transition: "transform 0.3s",
    });
    beginImageFrameProgressTimer()
    
  };

  const videoOnEnded = () => {
    const dom = document.getElementById(`video-${index}`);
    dom.currentTime = 0;
    showNextFrame()
  };

  const videoOnLoaded = (idx) => {
    console.log("on video loaded", `cover-${idx}`);
    const cover = document.getElementById(`cover-${idx}`);
    cover.style.display = "none";
  };

  const showPrepFrame = ()=> {
    cleanCurrenFrmeAction()
    const newIndex =  ((list ?? []).length + (index -1)) % (list ?? []).length;
    setIndex(newIndex);
    setConStyle({
      transform: `translateX(-${newIndex * conWidth}px)`,
      transition: "transform 0.3s",
    });
    beginImageFrameProgressTimer()
  }

  const timeUpdate = (e)=> {
    const dom = document.getElementById(`video-${index}`);
    // console.log("time update",  dom.currentTime / dom.duration)
    setPercent( dom.currentTime / dom.duration * 100)
  }

  return (
    <div className={classes.navs} >
     <div className={classes.progress}> 
      <Progress
        type="circle"
        trailColor="#ffffff33"
        percent={percent}
        strokeWidth={8}
        strokeColor="#ffffff99"
        width={30}
        showInfo={false}
        format={""}
      />
     </div>
      <div className={`${classes.nav} ${ classes.left}`} onClick={showPrepFrame} >
          <LeftIcon/>
      </div>
      <div className={`${classes.nav} ${ classes.right}`} onClick={showNextFrame}>
        <RightIcon/>
      </div>
     
      <div ref={contanerRef} className={classes.slice}>
      

        {videomModal && <ModalVideo onClose={closeVideoMoal} src={videoSrc} />}
        <div
          className={classes.items}
          style={conStyle}
          onTransitionEnd={onTransitionEnd}
        >
          {(list ?? []).map((b, i) => (
            <div className={classes.wrapper} style={{ position: "relative" }}>
              {b.media_type === 0 ? (
                <img className={classes.image1} src={b?.media_url} />
              ) : (
                <div>
                  <img
                    id={`cover-${i}`}
                    className={classes.image1}
                    src={b?.cover}
                  />
                  <video
                    id={`video-${i}`}
                    className={classes.image1}
                    controls={false}
                    autoPlay
                    playsInline
                    muted
                    onLoadedData={() => {
                      videoOnLoaded(i);
                    }}
                    onTimeUpdate={timeUpdate}
                    onEnded={videoOnEnded}
                  >
                    <source src={b.media_url} type="video/mp4" />
                  </video>
                </div>
              )}

              <div className={classes.image}>
                <div className={classes.name}>{b?.title}</div>
                <div className={classes.title}>{b?.description}</div>
                <div className={classes.detail_contaner}>
                  {b?.link && (
                    <DetailButtonIcon
                      className={classes.button}
                      onClick={() => window.open(b?.link, "__blank")}
                    ></DetailButtonIcon>
                  )}

                  {b.media_type == 1 && (
                    <BsPlayBtnFill
                      className={classes.openVideo}
                      onClick={() => {
                        openVideoModal(b.media_url);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={classes.mask}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
