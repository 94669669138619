import React, { useEffect, useState } from "react";
import classes from "./Detail.module.css";
import { Header, Catalog, Swiper } from "@/components";
import Card from "./Card";
import { fetchProductDetail } from "../../api";
import { ReactComponent as NavArrow } from "assets/navArrow.svg";
import { useApi } from "../../hook";
import { useRef } from "react";

import { useCatalog } from "./useCatalog";
import { ReactComponent as ArrowIcon } from "assets/navArrow.svg";

import { ContactForm } from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import Preview from "./Preview";
import events from "@/event";
import Editor from "../../components/Editor";

import { ReactComponent as BtnIcon } from "assets/btn5.svg";
import ImageViewer from "react-simple-image-viewer";


export default function Detail() {
  const { id = "" } = useParams();
  const navigator = useNavigate();
  const [index, setIndex] = useState(0);
  const [isShow, setIsShow] = useState(false);


  const [data] = useApi(fetchProductDetail, id);

  const [currentImage, setCurrentImage] = useState("0");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  const openImageViewer = (idx) => {
    setCurrentImage(idx);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(()=> {
    var is = []
    const i1 = document.querySelectorAll('#detail img')
    const i2 = document.querySelectorAll('#cover .cover')
    const images = [...i1, ...i2]
  
    images.forEach((dom, idx)=> {
      dom.style.cursor="pointer"
      is.push(dom.getAttribute("src"))
      dom.addEventListener("click", ()=> {
        openImageViewer(idx)
      })

    })
    setImages(is)   
    const videos = document.querySelectorAll('#detail video')
    videos.forEach((video) => {
      video.muted = true
    })

  }, [data])



  const handleClick = () => {
    const dom = document.getElementById("contact-form");
    if (dom) {
      dom.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (!data) return;
    events.emit("catalogObj", [
      { path: `/product/${data.parent_id}`, name: data.parent_name },
      { path: "", name: data.name },
    ]);

    return () => {
      events.emit("catalogObj", []);
    };
  }, [data]);

  if (!data) return null;

  return (
    <div className={classes.wrapper}>
      <Header title={data.name} desc={data.description} />

      <div className={classes.content}>
        <div className={classes.back} onClick={() => navigator(-1)}>
          <NavArrow className={classes.backIcon} />
          <span>返回搜索结果页</span>
        </div>
        <div id="cover" className={classes.card1}>
          <Card d={data} btn={BtnIcon} onClick={handleClick} />
        </div>
        <div id="detail" className={classes.card2}>
          <div className={classes.card2Content}>
            <Editor className={classes.textdd} data={data.extra} />
          </div>
        </div>

        {(data.application ?? []).length > 0 && (
          <div className={classes.card3}>
            <div className={classes.title}>应用案例</div>
            <Swiper list={data.application ?? []} count={4} gap={24}>
              {(d, i) => (
                <div className={classes.item}>
                  <img
                    className={classes.image3}
                    src={d.image[0]}
                    onClick={() => {
                      setIndex(i);
                      setIsShow(true);
                    }}
                  />
                  <div className={classes.title3}>{d.title}</div>
                </div>
              )}
            </Swiper>
          </div>
        )}

        {(data.relation_solution ?? []).length > 0 && (
          <div className={classes.card4}>
            <div className={classes.title}>相关解决方案</div>
            <Swiper list={data.relation_solution ?? []} count={2} gap={36}>
              {(d, i) => (
                <div className={classes.item2}>
                  <img className={classes.cover} src={d.cover} />
                  <div className={classes.itemTitle}>{d.title}</div>
                  <div
                    className={classes.button}
                    onClick={() => navigator(`/solution/detail/${d.id}`)}
                  >
                    继续了解
                    <ArrowIcon className={classes.icon} />
                  </div>
                </div>
              )}
            </Swiper>
          </div>
        )}

        <div id="contact-form" className={classes.card5}>
          <div className={classes.title}>取得联系</div>
          <div>
            <ContactForm />
          </div>
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            "background-color": "rgb(0 0 0 / 95%)",
            "padding": "100px",
            zIndex: 9999,
          }}
        />
      )}

      {isShow && (
        <Preview
          list={data.application}
          current={index}
          onClose={() => setIsShow(false)}
        />
      )}
    </div>
  );
}
