import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom';
import { Topbar, Footer, FixedNav } from './components'
import classes from './App.module.css'
import { useApi } from './hook';
import { fetchCatalogs, fetchHeadBanner } from './api';
import { CatalogContext } from './context';
import { ReactComponent as CloseIcon } from 'assets/close1.svg'
import events from '@/event'
import Navs from './components/Topbar/Navs';
import qs from 'query-string'


function App() {
  const [catalog] = useApi(fetchCatalogs)
  const [showFixedNav, setShowFixedNav] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const mainRef = useRef();
  const [isShowNav, setIsShowNav] = useState(false);


  const query = useMemo(
    () => qs.parse(window.location.search, { arrayFormat: 'comma' }),
    []
  )


  const onScroll = useCallback(() => {
    // mainRef.current.scrollTop = 0
  })

  useEffect(() => {
    // onScroll()
  }, [window.location.pathname])


  const handledScroll = (event) => {
    const scrollTop = (event.srcElement ? event.srcElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)
    if (scrollTop >= 400 && !showFixedNav) {
      setShowFixedNav(true)
    } else if (scrollTop < 400 && showFixedNav) {
      setShowFixedNav(false)
    }
  }

  const onNavChange = () => {
    if (!isShowNav) {
      onScroll()
    }
    setIsShowNav(!isShowNav)
  }

 


  useEffect(() => {
    window.addEventListener("scroll", handledScroll, true)
    return () => window.removeEventListener("scroll", handledScroll, true)
  })

  const param = useMemo(() => {
    return query?.preview === 'true' ? { status: 2 } : {}
  }, [query])

  const [data] = useApi(fetchHeadBanner, param);

  useEffect(() => {
    const headerBanner = window.sessionStorage.getItem('header_banners')
    if (data && data.media_url && headerBanner !== 'showed') {
      setIsShow(true)
    }
  }, [data])


  return (
    <Router>
      <CatalogContext.Provider value={{ catalog }}>
        <div className={classes.app}>
          {
            isShow && (
              <div className={classes.headBanner} style={{ backgroundImage: `url(${data?.media_url ?? ''})` }}>
                <CloseIcon className={classes.headImage} onClick={() => {
                  setIsShow(false)
                  window.sessionStorage.setItem('header_banners', 'showed')
                }} />
              </div>
            )
          }

          <Topbar className={classes.top} isShowNav={isShowNav} onNavChange={onNavChange} />
          <div ref={mainRef} id="main" className={classes.main} style={{ overflowY: isShowNav ? 'hidden' : 'overlay' }}>
            <div id="top" ></div>

            <Routes />
            <Footer className={classes.footer} />

            {
              isShowNav && <Navs onNavChange={() => setIsShowNav(false)} />
            }
          </div>
          {showFixedNav && (
            <FixedNav />
          )}
        </div>
      </CatalogContext.Provider>

    </Router>
  )
}

export default App
