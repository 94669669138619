import React, { useContext, useEffect, useState } from "react";
import classes from "./Product.module.css";
import { ReactComponent as BtnIcon } from "assets/btn2.svg";
import { Header, Catalog } from "@/components";
import { useMemo } from "react";
import Card from "./Card";
import { fetchProducts } from "../../api";
import { useApi, usePageApi } from "../../hook";
import { useCatalog } from "./useCatalog";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useQuerySetter from "../../hook/useQuerySetter";
import useQuery from "../../hook/useQuery";
import { throttle, debounce } from "lodash";
import { useCallback } from "react";
import events from "@/event";
import { Empty } from "antd";


export default function Product() {
  const { id = "" } = useParams();
  const { categoryId = "", name = "" } = useQuery();
  const setQuery = useQuerySetter();
  const location = useLocation();
  const nav = useNavigate();


  const history = useNavigate();
  const params = useMemo(() => {
    return {
      category_id: categoryId || id,
      name,
    };
  }, [id, categoryId, name]);

  const [secondCataList, firstCatalog] = useCatalog();

  const onInputChange = useCallback((value) => {
    setQuery({
      name: value,
    });
  }, []);

  useEffect(() => {
    if (!firstCatalog) return;
    events.emit("catalog", firstCatalog.name);

    return () => {
      events.emit("catalog", "");
    };
  }, [firstCatalog]);

  // const ondebounceInput = useMemo(() => {
  //   return debounce(onInputChange, 500);
  // }, [onInputChange]);

  const [data] = useApi(fetchProducts, params);

  useEffect(()=> {
    if (location.state) {
      document.getElementById("main").scrollTo({
        "top": 500,
        behavior: "smooth"
      })
    } else {
      document.getElementById("main").scrollTop = 10
    }
  }, [ data])

  const isEmpty = (data || []).length < 1;

  return (
    <div className={classes.wrapper}>
      <Header
        title={firstCatalog?.name ?? ""}
        desc={firstCatalog?.description ?? ""}
        cover={firstCatalog?.cover ?? ""}
      />
      <Catalog
        current={categoryId}
        list={[{ name: "所有", id: "" }, ...(secondCataList ?? [])]}
        placeholder="搜索产品"
        onChange={(id) => {
          nav(`${window.location.pathname}?categoryId=${id}`, {state: {target: "content"}});
        }}
        onInputChange={onInputChange}
      ></Catalog>

      <div id="content" className={classes.content}>
        {(data ?? []).map((d, i) => (
          <Card
            key={i}
            d={d}
            onClick={() => history(`/product/detail/${d.id}`)}
          />
        ))}
        {isEmpty && <Empty description={"没有匹配到数据"} />}
      </div>
    </div>
  );
}
