import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CatalogContext } from "../../context";


export function useCatalog() {
  const { id = "" } = useParams();
  const { catalog } = useContext(CatalogContext);
  const cataList = useMemo(() => {
    return (catalog ?? []).find((d) => d.name === "产品技术")?.categories ?? [];
  }, [catalog]);

  const firstCatalog = useMemo(() => {
    return cataList.find((d) => d.id === id);
  }, [cataList, id]);

  const secondCataList = useMemo(() => {
    if (!firstCatalog) return [];
    return firstCatalog.categories ?? [];
  }, [firstCatalog]);

  return [secondCataList, firstCatalog]
}